import { Table, TableColumn, Tree, checkbox, Select, Option } from 'element-ui';
import yyNavbar from '@/components/navbar.vue';
import 'vant/lib/index.less';
import 'yiyu-vant-ui/lib/styles/index.less';
import {
  Tag,
  Tabbar,
  TabbarItem,
  Icon,
  Search,
  Divider,
  Button,
  NavBar,
  DropdownMenu,
  DropdownItem,
  Calendar,
  Form,
  Field,
  Collapse,
  CollapseItem,
  Picker,
  Popup,
  Dialog,
  Toast,
  Loading,
  Image as VanImage,
  Checkbox,
  CheckboxGroup,
  DatetimePicker,
  RadioGroup,
  Radio,
  Cell,
  PullRefresh,
  Uploader,
  TreeSelect,
  Overlay,
  Tab,
  Tabs,
  Sticky,
  Popover,
  Switch,
  Badge,
} from 'vant';

export default function (VueConstructor) {
  // element
  VueConstructor.use(Table);
  VueConstructor.use(TableColumn);
  VueConstructor.use(Tree);
  VueConstructor.use(checkbox);
  VueConstructor.use(Select);
  VueConstructor.use(Option);
  // self
  VueConstructor.component('yy-navbar', yyNavbar);

  // vant
  VueConstructor.use(Tag)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Icon)
    .use(Search)
    .use(Divider)
    .use(Button)
    .use(NavBar)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Calendar)
    .use(Form)
    .use(Field)
    .use(Collapse)
    .use(CollapseItem)
    .use(Picker)
    .use(Popup)
    .use(Dialog)
    .use(Toast)
    .use(Loading)
    .use(Checkbox)
    .use(VanImage)
    .use(CheckboxGroup)
    .use(DatetimePicker)
    .use(Cell)
    .use(PullRefresh)
    .use(Uploader)
    .use(TreeSelect)
    .use(Overlay)
    .use(RadioGroup)
    .use(Radio)
    .use(Tab)
    .use(Tabs)
    .use(Sticky)
    .use(Popover)
    .use(Switch)
    .use(Badge);
}

export const larvaBase = (item = {}) => [
  {
    name: 'larvaProduct',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/more/larva/product.vue'),
    meta: {
      title: '育苗生产',
      activeKeepAlive: true,
    },
    query: {
      lo_key: item['lo_key'],
      lm_id: item['lm_id'],
      larvaKey: item['l_key'],
      farmKey: item['mc_key'],
    },
  },
  {
    // 育苗安排
    name: 'larvaLaunch',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/more/larva/launch.vue'),
    meta: {
      title: '育苗',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      status: item['status'],
      farmKey: item['mc_key'],
      larvaKey: item['l_key'],
      ss_key: item['ss_key'],
      sr_key: item['sr_key'],
      // alias: item['alias'], // 这个字段只是前端展示，实际后端要用到的是ss_key
    },
  },
  {
    name: 'matterDetail',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/produce/matter/detail.vue'),
    meta: {
      title: '重要事项',
    },
  },
  {
    name: 'matterEdit',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/produce/matter/edit.vue'),
    meta: {
      title: '重要事项',
    },
  },
  {
    name: 'larvaImport',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/more/larva/import.vue'),
    meta: {
      title: '外调苗',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      status: item['status'],
      larvaKey: item['l_key'],
    },
  },
  {
    name: 'larvaRequirement',
    component: () =>
      import(
        /* webpackChunkName: "cul" */ '@/views/more/larva/LarvaRequirement.vue'
      ),
  },
  {
    name: 'larvaRequirementEdit',
    component: () =>
      import(
        /* webpackChunkName: "cul" */ '@/views/more/larva/LarvaRequirementEdit.vue'
      ),
    meta: {
      title: '新增藻类需求',
    },
  },
  {
    name: 'estimate',
    component: () =>
      import(
        /* webpackChunkName: "cul" */ '@/views/produce/estimate/index.vue'
      ),
    meta: {
      title: '估苗数据记录',
    },
    query: {
      lm_id: item['lm_id'],
    },
  },
  {
    name: 'estimateEdit',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/produce/estimate/edit.vue'),
    meta: {
      title: '估苗',
    },
    query: {
      lm_id: item['lm_id'],
      lmce_key: item['lmce_key'],
      status: item['status'],
    },
  },
  {
    name: 'deal',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/produce/deal/index.vue'),
    meta: {
      title: '处理记录',
    },
    query: {
      lm_id: item['lm_id'],
    },
  },
  {
    name: 'dealEdit',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/produce/deal/edit.vue'),
    meta: {
      title: '处理',
    },
    query: {
      lm_id: item['lm_id'],
      status: item['status'],
      ld_key: item['ld_key'],
      farmKey: item['mc_key'],
    },
  },
  {
    name: 'remove',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/produce/remove/index.vue'),
    meta: {
      title: '排苗记录',
    },
    query: {
      lm_id: item['lm_id'],
    },
  },
  {
    name: 'removeEdit',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/produce/remove/edit.vue'),
    meta: {
      title: '排苗',
    },
    query: {
      lm_id: item['lm_id'],
      status: item['status'],
      lc_key: item['lc_key'],
      farmKey: item['mc_key'],
    },
  },
  {
    name: 'move',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/produce/move/index.vue'),
    meta: {
      title: '搬苗记录',
    },
    query: {
      lm_id: item['lm_id'],
      farmKey: item['mc_key'],
    },
  },
  {
    name: 'moveEdit',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/produce/move/edit.vue'),
    meta: {
      title: '搬苗',
    },
    query: {
      lm_id: item['lm_id'],
      status: item['status'],
      lm_key: item['lm_key'],
      farmKey: item['mc_key'],
    },
  },
  {
    name: 'outDetail',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/produce/out/detail.vue'),
    meta: {
      title: '出苗详情',
    },
    query: {
      lm_id: item['lm_id'],
    },
  },
  {
    name: 'outEdit',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/produce/out/edit.vue'),
    meta: {
      title: '出苗',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      lo_key: item['lo_key'],
      ha_key: item['ha_key'],
      lm_id: item['lm_id'],
    },
  },
  {
    name: 'larvaClear',
    component: () =>
      import(/* webpackChunkName: "cul" */ '@/views/more/larva/LarvaClear.vue'),
    path: '/larva/clear/:key',
  },
]

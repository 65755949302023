export const goodsBase = (item = {}) => [
  {
    name: 'goodsAdd',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/goods/goodsAdd.vue'
      ),
    meta: {
      title: '新增物品',
      activeKeepAlive: true,
    },
    query: {
      goods_key: item['goods_key'],
    },
  },
  {
    name: 'goods',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/goods/goods.vue'
      ),
    meta: {
      title: '物品管理',
    },
  },
  {
    name: 'goodsDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/goods/goodsDetail.vue'
      ),
    meta: {
      title: '物品详情',
    },
    query: {
      goods_key: item['goods_key'],
    },
    params: {
      activeSubPage: item.activeSubPage,
      doSearch: item.doSearch,
    },
  },
  {
    name: 'goodsUnitAdd',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/goods/unitAdd.vue'
      ),
    meta: {
      title: '新增单位',
    },
    query: {
      type: item['type'],
    },
  },
  {
    name: 'goodsStoreSetting',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/goods/storeSetting.vue'
      ),
    meta: {
      title: '设置初始库存',
      activeKeepAlive: true,
    },
    query: {
      type: item['type'],
      validity: item['validity'],
      unitList: item['unitList'],
    },
  },
  {
    name: 'goodsUnitList',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/goods/goodsUnitList.vue'
      ),
    meta: {
      title: '固定单位管理',
    },
  },
];

export const checkBase = (item = {}) => [
  {
    name: 'checkAdd',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/check/checkAdd.vue'
      ),
    meta: {
      title: '新增盘点',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
      key: item['key'],
      type: item['type'],
    },
  },
  {
    name: 'check',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/check/check.vue'
      ),
    meta: {
      title: '盘点管理',
    },
  },
  {
    name: 'checkDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/check/checkDetail.vue'
      ),
    meta: {
      title: '盘点详情',
    },
    query: {
      key: item['key'],
    },
  },

  {
    name: 'checkGoodsDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/check/checkGoodsDetail.vue'
      ),
    meta: {
      title: '物品详情',
    },
    query: {
      key: item['key'],
      goods_key: item['goods_key'],
    },
  },
]

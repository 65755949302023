<template>
  <div id="app">
    <keep-alive :include="cached">
      <!-- 使用 fullPath 作为 key, 保证页面能携带 query 缓存, 即可以推入 Path 相同, 但 query 却不同的页面. -->
      <router-view />
      <!-- <router-view /> -->
    </keep-alive>
  </div>
</template>

<script>
var _this;
export default {
  name: 'App',
  data() {
    return {
      cached: this.$store.state.basic.catched,
      historyLen: undefined, // 用来判断浏览器是前进还是后退
      tmpKey: undefined,
    };
  },
  watch: {
    // 指定缓存的路由：前进刷新，后退缓存
    $route(to) {
      this.historyLen = window.history.length;
      // console.log("路由长度", this.historyLen);
      if (to.meta && to.meta.keepAlive) {
        this.$store.commit('iskeepAlive', to.name);
      }

      this.cached = this.$store.state.basic.cached;

      if (to.path == '/FF_home') {
        this.handleHistory();
      }

      if (process.env.NODE_ENV === 'development') {
        // this.tmpKey = to.fullPath + new Date().getTime()
        this.tmpKey = undefined;
      } else {
        this.tmpKey = to.fullPath;
      }
    },
  },
  mounted() {
    this.flutterListener();

    this.$store.commit('setRoutes', {
      type: 'push',
      route: this.$route,
    });
    // this.popstateListener()
  },
  destroyed() {
    this.popstateListener('remove');
    window.removeEventListener('message', this.receiveMessage, false);
  },
  methods: {
    flutterListener() {
      if (window.navigator.userAgent == 'flutterApp') {
        window.removeEventListener('message', this.receiveMessage, false);
        window.addEventListener('message', this.receiveMessage, false);
      }
    },
    receiveMessage(data) {
      if (data) {
        if (data.msg == 'curPosition') {
          console.log('app监听到了');
          this.geoLoading = false;
          let result = data.result || {};
          sessionStorage['flutter-cur-position'] = JSON.stringify({
            addr: result.address,
            lat: result.latitude,
            lng: result.longitude,
          });
          // window.flutter_inappwebview
          //   ?.callHandler('jsChannel', {
          //     mag: 'endMap',
          //   })
          //   .then(function (result) {
          //     console.log('flutter返回的消息', result, 111)
          //   })
          window.removeEventListener('message', this.receiveMessage, false);
        }
      }
    },
    handleHistory() {
      history.pushState(null, null, document.URL);
    },
    // 监听浏览器的路由按键
    popstateListener(type = 'listen') {
      _this = this;
      if (type == 'listen') {
        window.addEventListener('popstate', () => {
          if (
            location.href.indexOf('FF_') !== -1 &&
            window.navigator.userAgent != 'flutterApp'
          ) {
            // 禁止退出首系统
            // console.log('禁止退出系统')
            // _this.handleHistory() // app使用场景可以不做这个处理
          }
        });
      } else if (type == 'remove') {
        window.addEventListener('remove', () => {
          // window.navigator.userAgent == "flutterApp"
          if (location.href.indexOf('FF_home') !== -1) {
            _this.handleHistory();
          }
        });
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 1rem;
  width: 100vw;
  max-width: 100vw;
  background: #fff;
  height: 100%;
  /* height: -webkit-fill-available; */
  /* 这里不能设overflow，不然粘性布局失效 */
}
span,
div {
  font-size: 0.9rem;
}

@media (min-width: 1024px) {
  body {
    background: #f6f8fa;
    display: flex;
    justify-content: center;
  }
  #app {
    width: 1024px;
    max-width: 1024px !important;
  }

  .yiyu-fixed,
  .van-overlay,
  .van-popup {
    width: 1024px !important;
    left: auto !important;
    right: auto !important;
  }

  .van-hairline--top-bottom.van-tabbar.van-tabbar--fixed {
    width: 1024px !important;
    left: auto !important;
    right: auto !important;
  }

  #yiyu-remark {
    left: 50% !important;
    max-width: 922px;
  }

  .van-sticky.van-sticky--fixed {
    width: 1024px !important;
    left: auto !important;
    right: auto !important;
  }
}
</style>

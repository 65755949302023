export const applyBase = (item = {}) => [
  {
    name: 'attendanceApply',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/attendance/apply/index.vue'
      ),
    meta: {
      title: '申请管理',
      keepAlive: true,
    },
    params: {
      tab: item['tab'],
    },
  },
  {
    name: 'applicationDetail',
    path: '/application/patch/detail/:key',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/attendance/apply/patchDetail.vue'
      ),
    meta: {
      title: '申请详情',
    },
    params: {
      key: item['atp_key'],
    },
  },
  {
    name: 'application',
    path: '/application',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/attendance/apply/application.vue'
      ),
    meta: {
      title: '申请',
      activeKeepAlive: true,
    },
    query: {
      atp_key: item['atp_key'],
      type: item['type'],
      again: item['again'],
    },
    params: {
      date: item['date'],
      com_type: item['com_type'],
    },
  },
]

export const recordBase = (item = {}) => [
  {
    name: 'recordList',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/record/list.vue'),
    meta: {
      title: '操作记录',
      activeKeepAlive: true,
    },
    query: {
      type: item['type'],
      lm_id: item['lm_id'],
    },
    params: {
      date: item['date'],
      key: item['key'],
    },
  },
  {
    name: 'recordDetail',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/record/detail.vue'),
    meta: {
      title: '操作记录详情',
    },
    query: {
      type: item['type'],
      key: item['key'],
      trip_type: item['trip_type'],
    },
  },
]

export default function getCompConf() {
  if (process.env.NODE_ENV === 'production') {
    switch (window.location.host) {
      case 'lutai-t.yiyu.plus':
        return {
          title: '智慧基地',
          // baseURL: 'https://lutai-s.yiyu.plus',
          // entrepotURL: 'https://lutai-s.yiyu.plus',
          // attenndanceURL: 'https://lutai-s.yiyu.plus',
          baseURL: 'https://lutai-s-t.yiyu.plus',
          entrepotURL: 'https://lutai-s-t.yiyu.plus', // 库存请求地址
          attenndanceURL: 'https://lutai-s-t.yiyu.plus', // 考勤请求地址
          logo: require('../../public/ic_launcher.png'),
        };
      case 'baolai.yiyu.plus':
        return {
          title: '宝莱集团',
          baseURL: 'https://baolai-s.yiyu.plus',
          entrepotURL: 'https://baolai-s.yiyu.plus',
          attenndanceURL: 'https://baolai-s.yiyu.plus',
          logo: require('../../public/ic_launcher.png'),
        };
      case 'lutai.yiyu.plus':
        return {
          title: '禄泰集团',
          baseURL: 'https://lutai-s.yiyu.plus',
          entrepotURL: 'https://lutai-s.yiyu.plus',
          attenndanceURL: 'https://lutai-s.yiyu.plus',
          appid: 'wxa5d440ebf7ec8b2a',
          // logo: require('@/assets/logos/lutai.png'),
        };
      case 'boao.kuaiyu.plus':
        return {
          title: '博翱',
          baseURL: 'https://boao-s.kuaiyu.plus',
          entrepotURL: 'https://boao-s.kuaiyu.plus',
          attenndanceURL: 'https://boao-s.kuaiyu.plus',
          logo: require('@/assets/logos/boao.png'),
        };
      default: {
        return {
          title: '智慧基地',
          baseURL: 'https://mc-s.yiyu.plus',
          appid: 'wxdd5c6eb07ece4ae6',
          logo: require('../../public/ic_launcher.png'),
        };
      }
    }
  } else {
    return {
      title: '智慧基地',
      baseURL: 'https://lutai-s-t.yiyu.plus',
      entrepotURL: 'https://lutai-s-t.yiyu.plus',
      attenndanceURL: 'https://lutai-s-t.yiyu.plus',

      /* baseURL: 'https://lutai-s.yiyu.plus',
      entrepotURL: 'https://lutai-s.yiyu.plus',
      attenndanceURL: 'https://lutai-s.yiyu.plus',*/

      // baseURL: 'https://mc.yiyu.plus',
      logo: require('../../public/ic_launcher.png'),
    };
  }
}

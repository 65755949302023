import { clockBase } from './clockRoutes'
import { statBase } from './statRoutes'
import { applyBase } from './applyRoutes'
import { setBase } from './setRoutes'

export const attendanceBase = (item = {}) => [
  {
    name: 'attendanceArea',
    component: () =>
      import(
        /* webpackChunkName: "attendance" */ '@/views/attendance/areaChose.vue'
      ),
    meta: {
      title: '考勤地点',
    },
    params: {
      lng: item['lng'],
      lat: item['lat'],
      radius: item['radius'],
      cached: true,
    },
  },
  ...clockBase(item),
  ...statBase(item),
  ...applyBase(item),
  ...setBase(item),
]

import { get } from '@/utils/http'
import { setToken } from './cookie'
import { getUrlSearch } from '@/utils/utils'
import getCompConf from '@/conf/comp'
export function isWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true // 是微信端
  } else {
    return false
  }
}

const gatway = 'https://open.weixin.qq.com/connect/oauth2/authorize'

const APPID = getCompConf().appid
const SCOPE = 'snsapi_base'
export const STATE = 'weChat'
const weixinConf = {
  getUrl(REDIRECT_URI) {
    REDIRECT_URI = encodeURIComponent(REDIRECT_URI)
    // 如果用户同意授权，页面将跳转至 redirect_uri/?code=CODE&state=STATE。
    return `${gatway}?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&state=${STATE}&connect_redirect=1#wechat_redirect`
  },
  getNext(next) {
    let code = getUrlSearch(window.location.href, 'code')
    get('/wx/wechatLogin', {
      code,
    }).then((res) => {
      if (res.code == 10000) {
        setToken(res.data.token)
        // 正在登录后又正在登录了一次
        let arr = window.location.href.split(`state=${STATE}#`)
        let redirect_uri = arr[arr.length - 1]
        next(redirect_uri)
      } else {
        // 重定向到登录页
        next(`/FF_login`)
      }
    })
  },
  isCanToken() {
    let href = window.location.href
    return getUrlSearch(href, 'state') == STATE && getUrlSearch(href, 'code')
  },
}

export default weixinConf

export const noticeBase = (item = {}) => [
  {
    name: 'notice',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/notice/index.vue'),
    meta: {
      title: '通知',
      keepAlive: true,
    },
  },
  {
    name: 'noticeDetail',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/notice/detail.vue'),
    meta: {
      title: '通知详情',
    },
    query: {
      k: item['notice_key'],
    },
  },
  {
    name: 'publish',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/notice/publish.vue'),
    meta: {
      title: '发布',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      k: item['notice_key'],
    },
    params: {
      title: item['title'],
      remark: item['remark'],
      priority: item['priority'],
      p: item['p'], // 角色
      user_key: item['user_key'],
      re: item['re'],
    },
  },
]

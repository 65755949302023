import { get, post } from '@/utils/http'

const setting = {
  // namespaced: true,
  state: {
    bags: [], // 印刷袋
    getBags: false,
    suppliers: [], // 来源
    getSuppliers: false,
    contacts: [], // 联系人
    getContacts: false,
    regions: [], // 区域
    getRegions: false,
    // persons: [],  // 邀请完成的成员
    // getPersons: false,
    // 用户自定义配置的数据
    userConf: {
      larvaType: {
        // 育苗类型
        type: 5,
        list: [],
        // 存在需要重新获取的情况
      },
      seedName: {
        // 种虾名称
        type: 4,
        list: [],
      },
      seedAlias: {
        // 种虾别名
        type: 1,
        list: [],
      },
      seedStrain: {
        // 种虾品系
        type: 2,
        list: [],
      },
      larvalStage: {
        // 育苗阶段
        type: 6,
        list: [],
      },
      growQualityTargetType: {
        // 弃用
        // 育苗质检指标类别
        type: 7,
        list: [],
      },
      growFeedTargetType: {
        // 育苗投喂指标类别
        type: 8,
        list: [],
      },
      seedQualityTargetType: {
        // 弃用
        // 种虾质检指标类别
        type: 9,
        list: [],
      },
      dectConfType: {
        // 质检指标（不区分幼苗、种虾、藻类）
        type: 15,
        list: [],
      },
      seedFeedTargetType: {
        // 种虾投喂指标类别
        type: 10,
        list: [],
      },
      seedStage: {
        // 种虾阶段
        type: 11,
        list: [],
      },
      algalSpecies: {
        // 藻类品种
        type: 12,
        params: 'breed',
        list: [],
      },
      algaeSource: {
        // 藻类来源
        type: 13,
        params: 'source',
        list: [],
      },
      dectObjType: {
        // 质检质检对象
        type: 14,
        list: [],
      },
      goodsType: {
        //物品类别
        type: 16,
        list: [],
      },
      supplier: {
        //供应商
        type: 17,
        list: [],
      },
      examineMan: {
        //采购审核人
        type: 18,
        list: [],
      },
      section: {
        //申请部门
        type: 19,
        list: [],
      },
    },
  },
  getters: {
    // get value from config obj, the default value of attribute is type
    USER_CONFIG_VALUE:
      (state) =>
      (configName, attr = 'type') => {
        return state.userConf[configName]?.[attr] ?? null
      },
  },
  mutations: {
    setUserConf: (state, obj) => {
      let type = obj['type']
      state['userConf'][type]['list'] = obj['list']
    },
    setBags: (state, bags) => {
      state.bags = bags
      state.getBags = true
    },
    setSuppliers: (state, suppliers) => {
      state.suppliers = suppliers
      state.getSuppliers = true
    },
    setContacts: (state, contacts) => {
      state.contacts = contacts
      state.getContacts = true
    },
    setRegions: (state, regions) => {
      state.regions = regions
      state.getRegions = true
    },
    setPersons: (state, persons) => {
      state.persons = persons
      state.getRegions = true
    },
  },
  actions: {
    // 获取用户自定义配置相关数据
    // yy_type: userConf的key
    /**
     *
     * @param {object} args
     * @param {string | string[]} args.yy_type 配置列表类型
     * @param {string | string[]} args.kw 搜索
     * @param {boolean=} args.forceUpdate 是否强制更新，默认false
     * @returns {Promise<object | object[]>} 传出类型与yy_type相对应
     */
    getUserConfList({ commit, state, getters }, args) {
      let url = args.url || '/conf/getNewList'
      let list = []
      const fetchConfig = (url, args) => {
        const parameters = {
          type: getters.USER_CONFIG_VALUE(args.yy_type, 'type'),
          keyword: args.kw,
        }
        return new Promise((resolve, reject) => {
          get(url, parameters)
            .then((res) => {
              if (res.code == 10000 && res.data) {
                // 将content里的内容取出来
                let list = res.data.list || []

                if (args.yy_type != 'larvaType') {
                  list.forEach((item) => {
                    if (args.yy_type == 'examineMan') {
                      item['name'] = item.content['name']
                      item['role'] = item.content['role']
                    } else {
                      for (let key in item.content) {
                        item[key] = item.content[key]
                      }
                    }
                  })
                }
                if (!args.kw) {
                  commit('setUserConf', {
                    type: args.yy_type,
                    list,
                  })
                }
              }
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
      }

      return new Promise((resolve, reject) => {
        if (!Array.isArray(args?.yy_type)) {
          // cached value

          if (args.yy_type == 'larvaType') {
            url = '/ltype/getLTypeList'
          }
          fetchConfig(url, args)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
        } else {
          const promises = []

          for (const key of args.yy_type) {
            if (!state.userConf[key]) continue

            if (key == 'larvaType') {
              url = '/ltype/getLTypeList'
            }
            promises.push(fetchConfig(url, { yy_type: key }))
          }

          Promise.all(promises)
            .then((res) => resolve(list.concat(res)))
            .catch((err) => reject(err))
        }
      })
    },
    // 编辑用户自定义配置相关数据
    editUserConfList({ dispatch, state }, args = { yy_type: 'larvaType' }) {
      args.type = state.userConf[args.yy_type]['type']
      return new Promise((resolve, reject) => {
        let url = args.url || '/conf/addNewOne'
        if (args.yy_type == 'larvaType') {
          url = '/ltype/addLType'
          args.lt_key = args.key
          args.title = args.content && args.content.name
        }
        post(url, args)
          .then((res) => {
            if (res.code == 10000) {
              if (args.url) {
                dispatch('getUserConfList', {
                  yy_type: args.yy_type,
                  url: '/entrepot_conf/getNewList',
                })
                console.log(args)
              } else {
                dispatch('getUserConfList', { yy_type: args.yy_type })
              }
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 删除种虾名称/别名/品系
    delUserConfList({ dispatch, state }, args = { yy_type: 'larvaType' }) {
      args.type = state.userConf[args.yy_type]['type']
      return new Promise((resolve, reject) => {
        let url = args.url || '/conf/delNewOne'
        if (args.yy_type == 'larvaType') {
          url = '/ltype/delLType'
          args.lt_key = args.key
        }
        post(url, args)
          .then((res) => {
            if (res.code == 10000) {
              if (args.url) {
                dispatch('getUserConfList', {
                  yy_type: args.yy_type,
                  url: '/entrepot_conf/getNewList',
                })
              } else {
                dispatch('getUserConfList', { yy_type: args.yy_type })
              }
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 获取来源
    getSupplier({ commit }) {
      return new Promise((resolve, reject) => {
        get('/supplier/getSupplier', {})
          .then((res) => {
            if (res.code == 10000 && res.data) {
              commit('setSuppliers', res.data.list)
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 编辑来源
    addSupplier({ dispatch }, args = {}) {
      return new Promise((resolve, reject) => {
        post('/supplier/addSupplier', args)
          .then((res) => {
            if (res.code == 10000) {
              dispatch('getSupplier')
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 删除来源
    delSupplier({ dispatch }, args = {}) {
      return new Promise((resolve, reject) => {
        post('/supplier/delSupplier', args)
          .then((res) => {
            if (res.code == 10000) {
              dispatch('getSupplier')
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 获取印刷袋
    getBag({ commit }) {
      return new Promise((resolve, reject) => {
        get('/bag/getBag', {})
          .then((res) => {
            if (res.code == 10000 && res.data) {
              commit('setBags', res.data.list)
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 编辑印刷袋
    addBag({ dispatch }, args = {}) {
      return new Promise((resolve, reject) => {
        post('/bag/addBag', args)
          .then((res) => {
            if (res.code == 10000) {
              dispatch('getBag')
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 删除印刷袋
    delBag({ dispatch }, args = {}) {
      return new Promise((resolve, reject) => {
        post('/bag/delBag', args)
          .then((res) => {
            if (res.code == 10000) {
              dispatch('getBag')
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 获取区域
    getRegion({ commit }) {
      return new Promise((resolve, reject) => {
        get('/region/getRegion', {})
          .then((res) => {
            if (res.code == 10000 && res.data) {
              commit('setRegions', res.data.list)
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 编辑区域
    addRegion({ dispatch }, args = {}) {
      return new Promise((resolve, reject) => {
        post('/region/addRegion', args)
          .then((res) => {
            if (res.code == 10000) {
              dispatch('getRegion')
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 删除区域
    delRegion({ dispatch }, args = {}) {
      return new Promise((resolve, reject) => {
        post('/region/delRegion', args)
          .then((res) => {
            if (res.code == 10000) {
              dispatch('getRegion')
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 获取联系人
    getContacts({ commit }) {
      return new Promise((resolve, reject) => {
        get('/contacts/getContactsList', {})
          .then((res) => {
            if (res.code == 10000 && res.data) {
              commit('setContacts', res.data.list)
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 编辑联系人
    addContacts({ dispatch }, args = {}) {
      return new Promise((resolve, reject) => {
        post('/contacts/addContacts', args)
          .then((res) => {
            if (res.code == 10000) {
              dispatch('getContacts')
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 删除联系人
    delContacts({ dispatch }, args = {}) {
      return new Promise((resolve, reject) => {
        post('/contacts/delContacts', args)
          .then((res) => {
            if (res.code == 10000) {
              dispatch('getContacts')
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    //   // 获取成员
    //   getPersons({ commit }) {
    //     return new Promise((resolve, reject) => {
    //       get("/member/getMember", {
    //         inv_situation: 'complete'
    //       })
    //         .then((res) => {
    //           if (res.code == 10000 && res.data) {
    //             commit("setPersons", res.data.list);
    //           }
    //           resolve(res);
    //         })
    //         .catch((err) => {
    //           reject(err);
    //         });
    //     });
    //   },
    //   // 编辑成员
    //   addPersons({ dispatch }, args = {}) {
    //     return new Promise((resolve, reject) => {
    //       post("/member/setMember", args)
    //         .then((res) => {
    //           if (res.code == 10000) {
    //             dispatch("getPersons");
    //           }
    //           resolve(res);
    //         })
    //         .catch((err) => {
    //           reject(err);
    //         });
    //     });
    //   },
    //   // 接受邀请
    //   acceptPersons() {

    //   },
    //   // 删除联系人
    //   delPersons({ dispatch }, args = {}) {
    //     return new Promise((resolve, reject) => {
    //       post("/member/delMember", args)
    //         .then((res) => {
    //           if (res.code == 10000) {
    //             dispatch("getPersons");
    //           }
    //           resolve(res);
    //         })
    //         .catch((err) => {
    //           reject(err);
    //         });
    //     });
    //   },
  },
}

export default setting

export const settingBase = (item = {}) => [
  {
    name: 'setting',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/index.vue'),
    meta: {
      title: '设置',
      keepAlive: true,
    },
  },
  {
    name: 'surivalRateSet',
    component: () =>
      import(
        /* webpackChunkName: "index" */ '@/views/setting/survival_rate.vue'
      ),
    meta: {
      title: '成活率设置',
    },
  },
  {
    name: 'settlementSet',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/settlement.vue'),
    meta: {
      title: '实结率设置',
    },
  },
  {
    name: 'breedDaySet',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/breed_day.vue'),
    meta: {
      title: '育苗天数设置',
    },
  },
  {
    name: 'bagSet',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/bag.vue'),
    meta: {
      title: '印刷袋设置',
    },
  },
  {
    name: 'nodeSet',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/node.vue'),
    meta: {
      title: '结点设置',
    },
  },
  {
    name: 'regionSet',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/region.vue'),
    meta: {
      title: '区域设置',
    },
  },
  // 支持用户自定义配置
  {
    name: 'confSet',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/confSet.vue'),
    meta: {
      title: '设置',
    },
    query: {
      type: item['type'],
    },
  },
  {
    name: 'orderSet',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/order.vue'),
    meta: {
      title: '订单默认需求设置',
    },
  },
  {
    name: 'supplierSet',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/supplier.vue'),
    meta: {
      title: '来源设置',
    },
  },
  {
    name: 'feedSet',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/feed.vue'),
    meta: {
      title: '投喂料设置',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
  },
  {
    name: 'transportSet',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/transport.vue'),
    meta: {
      title: '运输设置',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
  },
  {
    name: 'contactSet',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/setting/contact.vue'),
    meta: {
      title: '联系人设置',
    },
    query: {
      formPopup: item['formPopup'],
    },
  },
]

export const dectBase = (item = {}) => [
  {
    name: 'dectMgt',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/dect/DectMgt.vue'),
    meta: {
      title: '质检管理',
      keepAlive: true,
    },
  },
  {
    name: 'dectEdit',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/dect/DectEdit.vue'),
    meta: {
      title: '质检',
      activeKeepAlive: true,
    },
    query: {
      ws_type: item['ws_type'],
      test_type: 1,
      ss_key: item['ss_key'],
      mc_key: item['mc_key'],
      l_key: item['l_key'],
      algae_key: item['algae_key'],
      r_key: item['r_key'],
      at_key: item['at_key'],
    },
  },
  {
    name: 'dectDetail',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/dect/DectDetail.vue'),
    meta: {
      title: '质检报告',
    },
    query: {
      ws_type: item['ws_type'],
      test_type: 1,
      r_key: item['r_key'],
    },
  },
  {
    name: 'dectTemplateEdit',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/dect/DectTemplateEdit.vue'
      ),
    meta: {
      title: '质检模板',
      activeKeepAlive: true,
    },
    query: {
      key: item['key'], // 模板key
      ws_type: item['ws_type'],
      test_type: 1,
    },
  },
  {
    name: 'dectConfEdit',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/dect/DectConfEdit.vue'),
    meta: {
      title: '质检指标',
      activeKeepAlive: true,
    },
    query: {
      ws_type: item['ws_type'],
      test_type: 1,
      key: item['tt_key'],
    },
  },
  {
    name: 'dectSet',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/dect/DectSet.vue'),
    meta: {
      title: '质检配置',
      activeKeepAlive: true,
    },
    query: {
      ws_type: item['ws_type'],
      test_type: 1,
      tab: item['tab'],
    },
  },
  {
    name: 'dectApplyList',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/dect/DectApplyList.vue'
      ),
    meta: {
      title: '质检申请列表',
      keepAlive: true,
    },
    query: {
      ws_type: item['ws_type'],
      test_type: 1,
      l_key: item['l_key'],
      ss_key: item['ss_key'],
      algae_key: item['algae_key'],
      mc_key: item['mc_key'],
    },
  },
  {
    name: 'dectApplyEdit',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/dect/DectApplyEdit.vue'
      ),
    meta: {
      title: '质检申请',
      activeKeepAlive: true,
    },
    query: {
      ws_type: item['ws_type'],
      test_type: 1,
      ss_key: item['ss_key'],
      mc_key: item['mc_key'],
      l_key: item['l_key'],
      algae_key: item['algae_key'],
      r_key: item['r_key'],
      at_key: item['at_key'],
    },
  },
  // 新增指标和投喂一样
]

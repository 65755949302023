const themeOpts = {
  mainColor: '#fd7739',
  mainRgb: '255, 117, 46',
  textColor: '#52627c',
  titleColor: '#182a52',
  bgColor: '#f6f8fa',
  lineColor: '#f6f8fa',
  bgRgb: '246, 248, 250',
}

export const MAIN_COLOR = themeOpts.mainColor
export const MAIN_RGB = themeOpts.mainRgb
export default themeOpts

import axios from 'axios'
import { getToken } from './cookie'
import yapi from '@/api/middleware/yapi'
import auth from '@/api/middleware/auth'
import errorHandle from '@/api/middleware/errorHandler'
import { omitByUndefined } from './object'
import { isObject, isString, omitBy } from 'lodash'
import { newGuid } from './utils'
import getCompConf from '@/conf/comp'

// import { removePending, addPending } from '@/api/middleware/pending'

axios.defaults.timeout = 10 * 1000

axios.defaults.baseURL = getCompConf().baseURL

//http request 拦截器
axios.interceptors.request.use((config) => {
  // removePending(config) // 在请求开始前，对之前的请求做检查取消操作
  // addPending(config) // 将当前请求添加到 pending 中

  if (config.url.match(/entrepot/)) {
    config.baseURL = getCompConf().entrepotURL
  } else if (config.url.startsWith('/attend_')) {
    config.baseURL = getCompConf().attenndanceURL
  }

  const token = getToken()
  const uni = newGuid()

  if (config.params && config.params['token'] == null) {
    config.params['token'] = token
    config.params['uni'] = uni
  } else if (config.data && config.data['token'] == null) {
    config.data['token'] = token
    config.data['uni'] = uni
  }

  if (config.headers['Content-Type'] === 'multipart/form-data') {
    config.data?.append('token', token)
    config.data?.append('uni', uni)
  }
  return config
})
axios.interceptors.request.use(yapi)

//http response 拦截器
axios.interceptors.response.use(auth)
axios.interceptors.response.use((response) => {
  // removePending(response) // 在请求结束后，移除本次请求
  return response
}, errorHandle)

const preprocessParams = (params) => {
  if (!isObject(params)) {
    console.warn('params 需要是对象')
    return {}
  }

  // 移除字符串为空参数
  params = omitBy(params, (item) => {
    return isString(item) ? (item.length > 0 ? false : true) : false
  })

  return omitByUndefined(params)
}

/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: preprocessParams(params),
      })
      .then((response) => resolve(response?.data || {}))
      .catch((err) => reject(err))
  })
}

/**
 * 封装post请求
 * @param url
 * @param params
 * @param config
 * @returns {Promise}
 */
export function post(url, params = {}, config) {
  return new Promise((resolve, reject) => {
    axios
      // .post(url, omitByUndefined(params), config)
      .post(url, params, config)
      .then((res) => resolve(res?.data || {}))
      .catch((err) => reject(err))
  })
}

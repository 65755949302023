import { applyBase } from './applyRoutes';
import { goodsBase } from './goodsRoutes';
import { purchaseBase } from './purchaseRoutes';
import { deliveryBase } from './deliveryRoutes';
import { warehousesBase } from './warehousesRoutes';
import { storageBase } from './storageRoutes';
import { checkBase } from './checkRoutes';
import { allotBase } from './allotRoutes';
import { returnGoodsBase } from './returnGoodsRoutes';

export const storeBase = (item = {}) => [
  {
    name: 'storeMgt',
    component: () =>
      import(/* webpackChunkName: "store" */ '@/views/store/storeMgt.vue'),
    meta: {
      title: '库存管理',
      keepAlive: true,
    },
  },

  {
    name: 'goodsList',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/components/goodsList.vue'
      ),
    meta: {
      title: '物品列表',
      keepAlive: true,
    },
    query: {
      applyType: item['applyType'],
      goodsList: item['goodsList'],
      wh_key: item['wh_key'],
      isUpDataList: item['isUpDataList'],
    },
  },
  {
    name: 'statistics',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/statistics/statistics.vue'
      ),
    meta: {
      title: '统计',
      keepAlive: true,
    },
    query: {
      type: item['type'],
    },
  },
  ...applyBase(item),
  ...goodsBase(item),
  ...purchaseBase(item),
  ...deliveryBase(item),
  ...warehousesBase(item),
  ...storageBase(item),
  ...checkBase(item),
  ...allotBase(item),
  ...returnGoodsBase(item),
];

export const storageBase = (item = {}) => [
  {
    name: 'storageAdd',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/storage/storageAdd.vue'
      ),
    meta: {
      title: '新增入库',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
      goodskeyList: item['goodskeyList'],
      storage_key: item['storage_key'],
      keyword: item['keyword'],
    },
  },
  {
    name: 'storageConfirm',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/storage/storageConfirm.vue'
      ),
    meta: {
      title: '入库确认',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
      key: item['key'],
    },
  },
  {
    name: 'storage',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/storage/storage.vue'
      ),
    meta: {
      title: '入库管理',
    },
  },
  {
    name: 'storageDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/storage/storageDetail.vue'
      ),
    meta: {
      title: '入库详情',
    },
    query: {
      key: item['key'],
    },
  },

  {
    name: 'storageGoodsDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/storage/storageGoodsDetail.vue'
      ),
    meta: {
      title: '物品详情',
    },
    query: {
      storage_key: item['storage_key'],
      goods_key: item['goods_key'],
    },
  },
];

<template>
  <div class="yiyu-height">
    <div id="yiyu-navbar" class="yiyu-fixed">
      <van-nav-bar :title="title" left-arrow @click-left="onClickLeft">
        <template #right>
          <slot name="right" v-if="$slots.right"></slot>
          <template v-else>
            <img
              @click="toHome"
              class="yiyu-navbar-img"
              src="@/assets/icons/home_circle.png"
              alt=""
            />
            <span @click="toHome">首页</span>
          </template>
        </template>

        <template #left>
          <van-icon name="arrow-left" v-if="backShow" /><span v-if="backShow"
            >返回</span
          >
        </template>
      </van-nav-bar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navbar',
  props: {
    tabTitle: String,
    callback: {
      type: String,
    },
  },
  computed: {
    backShow() {
      // let from = this.$store.state.basic.fromUrl
      // if (from.name == null) { return false }
      // else { return true }
      return true
    },
    title() {
      return this.tabTitle || document.title
    },
  },
  watch: {
    title(val) {
      document.title = val || document.title
    },
  },
  methods: {
    onClickLeft() {
      if (!this.backShow) return
      if (this.callback) this.$emit(this.callback)
      else {
        this.$go(-1, this)
      }
    },
    toHome() {
      // 方法一：
      let path = 'FF_home'
      if (!this.IS_PAGE(path)) {
        path = 'FF_more'
      }

      this.$push(this, { name: path }).then(() => {
        // location.reload(); 体验不是很好
      })

      // 方法二：无效
      // window.location = window.location.origin
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-height {
  height: 3rem;
  background: #fff;
}
#yiyu-navbar {
  display: flex;
  align-items: center;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2.87rem;
  z-index: 999;
  box-sizing: border-box;

  .yiyu-navbar-img {
    width: 25px;
    height: 25px;
  }

  .van-nav-bar {
    flex-basis: 100%;
  }

  a {
    display: flex;
    align-items: center;
    color: @mainColor;
    span {
      white-space: nowrap;
    }
  }

  span {
    color: @mainColor;
  }

  /deep/.van-nav-bar__right {
    padding-right: 16px;
  }

  /deep/ .van-nav-bar__left {
    font-size: 1.1rem;
  }

  /deep/ .van-nav-bar__title {
    font-size: 1rem;
  }
}
</style>

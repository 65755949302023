export const deliveryBase = (item = {}) => [
  {
    name: 'deliveryAdd',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/delivery/deliveryAdd.vue'
      ),
    meta: {
      title: '新增出库',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
      goodskeyList: item['goodskeyList'],

      key: item['key'],
    },
  },
  {
    name: 'deliveryConfirm',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/delivery/deliveryConfirm.vue'
      ),
    meta: {
      title: '出库确认',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
      key: item['key'],
    },
  },
  {
    name: 'delivery',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/delivery/delivery.vue'
      ),
    meta: {
      title: '出库管理',
    },
  },
  {
    name: 'deliveryDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/delivery/deliveryDetail.vue'
      ),
    meta: {
      title: '出库详情',
    },
    query: {
      key: item['key'],
    },
  },

  {
    name: 'deliveryGoodsDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/delivery/deliveryGoodsDetail.vue'
      ),
    meta: {
      title: '物品详情',
    },
    query: {
      delivery_key: item['delivery_key'],
      goods_key: item['goods_key'],
    },
  },
];

export const seedBase = (item = {}) => [
  {
    name: 'sBatchDetail',
    component: () =>
      import(/* webpackChunkName: "seed" */ '@/views/seed/BatchDetail.vue'),
    meta: {
      title: '批次详情',
      keepAlive: true,
    },
    query: {
      ss_key: item['ss_key'],
    },
  },
  {
    name: 'sBatchEdit',
    component: () =>
      import(/* webpackChunkName: "seed" */ '@/views/seed/BatchEdit.vue'),
    meta: {
      title: '种虾', // 安排种虾 / 淘汰种虾
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      ss_key: item['ss_key'],
      form_type: item['form_type'], // over为淘汰
    },
  },
  {
    name: 'sBatchShrimpEdit',
    component: () =>
      import(/* webpackChunkName: "seed" */ '@/views/seed/BatchShrimpEdit.vue'),
    meta: {
      title: '接虾',
    },
    query: {
      status: item['status'],
      ss_key: item['ss_key'],
    },
  },
  {
    name: 'sDailyList',
    component: () =>
      import(/* webpackChunkName: "seed" */ '@/views/seed/BatchDailyList.vue'),
    meta: {
      title: '每日数据',
    },
    query: {
      ss_key: item['ss_key'],
    },
  },
  {
    name: 'sDailyEdit',
    component: () =>
      import(/* webpackChunkName: "seed" */ '@/views/seed/BatchDailyEdit.vue'),
    meta: {
      title: '每日数据记录',
      activeKeepAlive: true,
    },
    query: {
      // 通过是否有这两个字段来判断新增还是编辑
      sr_key: item['sr_key'],
      ss_key: item['ss_key'],
      type: item['type'],
    },
  },
  {
    name: 'sDailyDetail',
    component: () =>
      import(
        /* webpackChunkName: "seed" */ '@/views/seed/BatchDailyDetail.vue'
      ),
    meta: {
      title: '数据详情',
    },
    query: {
      ss_key: item['ss_key'],
      sr_key: item['sr_key'],
    },
  },
  {
    name: 'dailyFeed',
    component: () =>
      import(/* webpackChunkName: "seed" */ '@/views/tech/dailyFeed.vue'),
    meta: {
      title: '每日投喂信息',
    },
    query: {
      lm_id: item['lm_id'],
      ss_key: item['ss_key'],
      date: item['date'],
    },
  },
  {
    name: 'sArrangeList',
    component: () =>
      import(
        /* webpackChunkName: "seed" */ '@/views/seed/BatchArrangeList.vue'
      ),
    meta: {
      title: '幼体管理',
      keepAlive: true,
    },
    query: {
      ss_key: item['ss_key'],
      ss_batch: item['ss_batch'],
      start_at: item.start_at,
      end_at: item.end_at,
    },
  },
];

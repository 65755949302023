export const warehousesBase = (item = {}) => [
  {
    name: 'warehousesAdd',
    component: () =>
      import(
        /* webpackChunkName: "store" */
        '@/views/store/application/warehouses/management/WarehousesAdd.vue'
      ),
    meta: {
      title: '新增仓库',
    },
    query: {
      wh_key: item['wh_key'],
    },
  },
  {
    name: 'warehouses',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/warehouses/management/Warehouses.vue'
      ),
    meta: {
      title: '仓库管理',
    },
  },
  {
    name: 'warehousesGoodsDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */
        '@/views/store/application/warehouses/management/warehousesGoodsDetail.vue'
      ),
    meta: {
      title: '仓库物品详情',
    },
    query: {
      goods_key: item['goods_key'],
      wh_key: item['wh_key'],
    },
  },
  {
    name: 'warehousesDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */
        '@/views/store/application/warehouses/management/WarehousesDetail.vue'
      ),
    meta: {
      title: '仓库详情',
    },
    query: {
      wh_key: item['wh_key'],
    },
  },
]

import Vue from 'vue'
import App from './App.vue'

import { router } from '@/router'
import { registerDirective } from '@/utils/directive'
import { post, get } from './utils/http'
import themeOpts from './utils/globar'
import { go, push, replace, toPage } from '@/router/toPage'

import store from '@/store/index'
import dayjs from 'dayjs'
import yyNavbar from '@/components/navbar.vue'
import vuexMixin from '@/mixin/vuexMixin'
import { setTheme } from 'yiyu-vant-ui/lib/_utils'

import '@/router/permission.js'
import '@vant/touch-emulator' // 桌面端适配
import '@/utils/numberPrecision' // 小数精度
// import './registerServiceWorker' // PWA

import '@/styles/index.less' // global css
import '@/styles/tailwind.css' // https://tailwindcss.com/
import 'animate.css'
import 'tippy.js/dist/tippy.css'
import registerGlobalComponent from './globalComponent'
// eslint-disable-next-line no-unused-vars
let vConsole
if (
  location.hostname != 'localhost' &&
  (location.href.includes('vconsole=1') ||
    localStorage['yiyu-mc-phone']?.includes('1587650'))
) {
  import('vconsole').then((VConsole) => {
    vConsole = new VConsole.default()
  })
}

setTheme(Vue, themeOpts)
Vue.mixin(vuexMixin)

//定义全局变量
Vue.prototype.$post = post
Vue.prototype.$get = get

Vue.prototype.$go = go
Vue.prototype.$push = push
Vue.prototype.$replace = replace
Vue.prototype.$toPage = toPage

// 使用教程：https://dayjs.gitee.io/docs/zh-CN/display/format
// 常用 this.$dayjs(item).format('YY/MM/DD HH:mm')
Vue.prototype.$dayjs = function () {
  if (arguments[0] === null) {
    return {
      format() {
        // console.log('$dayjs:接收的时间是个null')
        return ''
      },
    }
  } else {
    // undefined 会返回此时此刻
    return dayjs(...arguments)
  }
}

registerGlobalComponent(Vue)
registerDirective(Vue)

new Vue({
  router,
  store,
  el: '#app',
  components: {
    yyNavbar,
  },
  render: (h) => h(App),
})

// navigator.serviceWorker.getRegistrations().then(function (registrations) {
//   for (let registration of registrations) {
//     registration.unregister()
//   }
// })

// eslint-disable-next-line no-undef
console.log('version: ', __COMMIT_HASH__)

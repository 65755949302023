// 对象转对象数组
export function objToArr(obj, key = 'key', value = 'value') {
  let arr = []
  for (let item in obj) {
    arr.push({
      [key]: item,
      [value]: obj[item],
    })
  }
  return arr
}

// 数组转对象
export function arrToObj(arr, key = 'key', value = 'value') {
  let obj = {}
  arr &&
    arr.forEach((item) => {
      obj[item[key]] = item[value]
    })
  return obj
}

// 根据key返回value
export function getKeyValue(key, arr, valueKey = 'value', keyName = 'key') {
  return getKeyObj(key, arr, keyName)[valueKey] || '未知'
}

// 根据key返回 key所在的对象
export function getKeyObj(key, arr = [], keyName = 'key') {
  if (Array.isArray(arr)) {
    let filter = arr.filter((item) => item[keyName] == key)
    if (filter.length) {
      return filter[0]
    }
  }

  return {}
}

/**
 * 判断两个数组是否相同
 * @param {*} arr1
 * @param {*} arr2
 * @returns Boolean
 */
export function compareArr(arr1 = [], arr2 = []) {
  arr1 = deepCopy(arr1)
  arr2 = deepCopy(arr2)
  return (
    arr1.length === arr2.length &&
    arr1.sort().toString() === arr2.sort().toString()
  )
}

// /**
//  * 获取 由对象数组 指定属性 组成的数组
//  * @param {*} arr 对象数组
//  * @param {*} keyName key值
//  */
// export function getKeyArr(arr = [], keyName = 'key') {
//   return arr.reduce((p, c) => [...p, c[keyName]], [])
// }

/**
 * 获取 由对象数组 指定属性 组成的数组
 * @param {*} arr 对象数组
 * @param {*} keyName key值
 * @param {*} keyName1 key值
 */
export function getKeyArr(arr = [], keyName = 'key', keyName1) {
  return arr.reduce((p, c) => {
    let val = c[keyName]
    if (keyName1) {
      val += '(' + c[keyName1] + ')'
    }
    return [...p, val]
  }, [])
}

// 将对象中的所有数字类型都转成字符串类型
export function numToStr(obj) {
  for (let i in obj) {
    if (typeof obj[i] == 'number') {
      obj[i] = String(obj[i])
    } else if (Array.isArray(obj)) {
      obj.forEach((item) => {
        if (typeof item == 'object' && JSON.stringify(item) != '{}') {
          for (let j in item) {
            if (typeof item[j] == 'number') {
              item[j] = String(item[j])
            }
          }
        }
      })
    }
  }
  return obj
}

// 阿拉伯数字转汉字
export function sectionToChinese(section) {
  let num = section
  var chnNumChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  var chnUnitChar = ['', '十', '百', '千', '万', '亿', '万亿', '亿亿']
  var strIns = '',
    chnStr = ''
  var unitPos = 0
  var zero = true
  if (section == 0) {
    return chnNumChar[0]
  }
  while (section > 0) {
    var v = section % 10
    if (v === 0) {
      if (!zero) {
        zero = true
        chnStr = chnNumChar[v] + chnStr
      }
    } else {
      zero = false
      strIns = chnNumChar[v] == '一' && num < 20 && num > 9 ? '' : chnNumChar[v]
      strIns += chnUnitChar[unitPos]
      chnStr = strIns + chnStr
    }
    unitPos++
    section = Math.floor(section / 10)
  }
  return chnStr
}

// 将数据进行分类 [{a: '11',aName: '11', b: '22},{a: '11',aName: '11',b: '23}]
// {11: {a: '11', aName: '11', children: []}}
// => [{a: '11',aName: '11',children:[{},{}]}]
export function classifyData(
  arr,
  pKey,
  pName,
  type = 'arr',
  childrenName = 'children',
) {
  let _arr = []
  let _obj = {}
  if (Array.isArray(arr)) {
    arr.forEach((item) => {
      if (_obj[item[pKey]]) {
        _obj[item[pKey]][childrenName].push(item)
      } else {
        _obj[item[pKey]] = {
          ...item,
          [pKey]: item[pKey],
          [pName]: item[pName],
          [childrenName]: [item],
        }
      }
    })
  }

  if (type == 'arr') {
    for (let key in _obj) {
      let item = _obj[key]
      _arr.push({
        ...item,
        [pKey]: item[pKey],
        [pName]: item[pName],
        [childrenName]: item[childrenName],
      })
    }
    // console.log("分类后的数据",_arr);
    return _arr
  } else {
    return _obj
  }
}

// 主要用于路由的数据处理
export function strToArr(data) {
  if (Array.isArray(data)) return data
  else if (data) return [data]
  else return []
}

// 节流：由第一个触发的事件说得算，避免频繁的DOM操作
export function throttle(fn, interval, params) {
  let last = 0

  return () => {
    let now = +new Date()

    if (now - last >= interval) {
      last = now
      fn(...params)
    }
  }
}

// 深拷贝
export function deepCopy(data) {
  if (typeof data === 'object' && data) {
    let val = Array.isArray(data) ? [] : {}
    for (let i in data) {
      // 如果里面有嵌套，就继续递归
      val[i] = deepCopy(data[i])
    }
    return val
  } else {
    return data
  }
}

export function isPc() {
  var userAgentInfo = navigator.userAgent
  var Agents = new Array(
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  )
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

// 合并对象数组
// 指定对象属性相同的则视为同一对象，其余属性优先取arr1有值的那个
// Object.assign是浅拷贝
export function mergeArr(arr1 = [], arr2 = [], key) {
  let arr = deepCopy(arr1)
  let keys = []
  arr.forEach((item) => {
    keys.push(item[key])
    let item2 = arr2.filter((item1) => item1[key] == item[key])[0]
    if (item2) {
      for (let key in item) {
        item[key] = item[key] || item2[key]
      }
    }
  })

  arr2.forEach((item) => {
    if (!keys.includes(item[key])) {
      arr.push(item)
    }
  })

  return arr
}

// arr2没有指定的key，返回的arr也不可以有
export function mergeConcatArr(arr1, arr2, key) {
  let arr = []
  let keys = []
  let arr2Keys = []

  if (Array.isArray(arr2)) {
    arr2.forEach((item) => {
      arr2Keys.push(item[key])
    })
  }

  if (Array.isArray(arr1)) {
    arr1.forEach((item) => {
      if (arr2Keys.includes(item[key])) {
        keys.push(item[key])
        let item2 = arr2.filter((item1) => item1[key] == item[key])[0]
        if (item2) {
          for (let key in item) {
            item[key] = item[key] || item2[key]
          }
        }
        arr.push(item)
      }
    })
  }

  if (Array.isArray(arr2)) {
    arr2.forEach((item) => {
      if (!keys.includes(item[key])) {
        arr.push(item)
      }
    })
  }

  return arr
}

// 成活率计算
export function getRate(son, parent) {
  let num = (son / parent) * 100
  return (num.toFixed(2) * 100) / 100
}

// 对象数组去重
export function distinctArr(arr, value) {
  let obj = {}
  return arr.reduce((cur, next) => {
    obj[next[value]] ? '' : (obj[next[value]] = true && cur.push(next))
    return cur
  }, [])
}

// 将字符串、数组统一成数组
export function convertToArr(data) {
  let arr = []
  if (Array.isArray(data)) {
    arr = data
  } else if (data) {
    arr = [data]
  }
  return arr
}

export const isFunction = (val) => typeof val === 'function'

export function newGuid() {
  var guid = ''
  for (var i = 1; i <= 32; i++) {
    var n = Math.floor(Math.random() * 16.0).toString(16)
    guid += n
    if (i == 8 || i == 12 || i == 16 || i == 20) guid += '-'
  }
  return guid
}

export function getUrlSearch(url, name) {
  var reg = new RegExp('(^|&?)' + name + '=([^&]*)(&|$)') //构造一个含有目标参数的正则表达式对象
  var r = url.substr(1).match(reg) //匹配目标参数
  if (r != null) return decodeURI(r[2])
  return null //返回参数值
}

export function noop() {}

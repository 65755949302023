function getMaxPower(arg1 = '', arg2 = '') {
  let len1 = arg1.toString().split('.')[1]?.length || 0
  let len2 = arg2.toString().split('.')[1]?.length || 0
  let n = Math.max(len1, len2)
  return Math.pow(10, n)
}

Number.prototype.add = function (arg) {
  let pow = getMaxPower(this, arg)
  return (this * pow + arg * pow) / pow
}

Number.prototype.sub = function (arg) {
  let pow = getMaxPower(this, arg)
  return (this * pow - arg * pow) / pow
}

Number.prototype.mul = function (arg) {
  let pow = getMaxPower(this, arg)
  return (this * pow * (arg * pow)) / (pow * pow)
}

Number.prototype.div = function (arg) {
  let pow = getMaxPower(this, arg)
  return (this * pow) / (arg * pow)
}

export const accept = (item = {}) => [
  {
    name: 'acceptList',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/accept/acceptList.vue'),
    meta: {
      title: '验收单管理',
      keepAlive: true,
    },
  },
  {
    name: 'acceptEdit',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/accept/acceptEdit.vue'),
    query: {
      ca_key: item['ca_key'],
    },
    meta: {
      title: '新增验收单',
      activeKeepAlive: true,
    },
  },
  {
    name: 'acceptDetail',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/accept/acceptDetail.vue'),
    query: {
      ca_key: item['ca_key'],
    },
    meta: {
      title: '验收详情',
    },
  },
];

import store from '@/store';
import { router } from '@/router';
import { isEmpty } from 'lodash';
import { jumpMapFn } from './index';

// 如果没有上一页，则跳到指定的页面
export function go(n, _this) {
  return new Promise((resolve) => {
    _this.$router.go(n);

    // store.commit('setRoutes', {
    //   type: 'push',
    //   route: n,
    // })

    resolve();
  });
}

export function push(_this, route) {
  if (isEmpty(_this)) _this.$router = router;
  return new Promise((resolve, reject) => {
    _this.$router
      .push({
        name: route.name,
        path: route.path,
        query: route.query,
        params: route.params,
      })
      .then((response) => {
        // store.commit('setRoutes', {
        //   type: 'push',
        //   route: route,
        // })
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function replace(_this, route) {
  if (arguments.length == 1) {
    _this.$router = router;
    route = arguments[0];
  }

  return new Promise((resolve, reject) => {
    _this.$router
      .replace({
        name: route.name,
        path: route.path,
        query: route.query,
        params: route.params,
      })
      .then((response) => {
        // store.commit('setRoutes', {
        //   type: 'replace',
        //   route: route,
        // })
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// routeName = 路由名 + '-' + status/type
// 最后一个参数是为了避免出现 字段不统一 的情况
export function toPage(
  _this, // 可不传
  routeName,
  item = {},
  {
    key = 'key',
    kw = 'kw',
    route_type = '$push', // 路由方式
    cached = 'cached', // bool 标记是否缓存当前页面
    date = 'date',
    lm_id = 'lm_id', // 育苗基地key
    or_id = 'or_id', // 订单key
    home_type = 'home_type',
    type = 'type',
    trip_type = 'trip_type',
  } = {}
) {
  if (_this === null || typeof _this === 'string') {
    item = routeName;
    routeName = _this;
    _this = this;
  }

  let data = {};

  switch (routeName) {
    ////////////////// 首页start
    case 'FF_home':
      data = {
        name: routeName,
        query: {
          type: item[home_type],
        },
        params: {
          date: item[date], // rele 新增关联专用
          lm_id: item[lm_id], // rele 新增关联专用
          or_id: item[or_id], // rele 关联批次专用
          kw: item[kw],
        },
      };
      break;
    ////////////////// 首页end

    // 操作
    // 操作记录详情
    case 'recordDetail':
      data = {
        name: routeName,
        query: { key: item[key], type: item[type], trip_type: item[trip_type] },
      };
      break;

    default:
      data = { name: routeName };
      break;
  }

  let jumpMap = jumpMapFn(item);

  if (jumpMap?.[routeName]) {
    data = jumpMap[routeName];
  }

  if (item?.[cached]) {
    if (data.params) {
      data.params.cached = item[cached];
    } else {
      data.params = {
        cached: item[cached],
      };
    }
  }

  if (item?.['back']) {
    if (data.params) {
      data.params['back'] = item['back'];
    } else {
      data.params = {
        back: item['back'],
      };
    }
  }

  if (data.name && store.state.permit.removeRouters.includes(data.name)) {
    this.$toast.fail('您没有该操作权限哦');
    return;
  }

  _this[route_type](_this, data);
}

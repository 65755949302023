// 存放 生产页面相关 状态

export const produce_mutations = {
  ACTIVE_TAB: 'ACTIVE_TAB',
  UPDATE_TABDATA: 'UPDATE_TABDATA',
}

const produce = {
  state: () => ({
    tabActive: undefined,
    tabData: [
      {
        name: 'sBatch',
        label: '种虾',
        total: 0,
        handle: 'SEED_BATCH_SEE',
      },
      {
        name: 'larva',
        label: '育苗',
        total: 0,
        handle: 'LARVA_BATCH_SEE',
      },
      {
        name: 'algae',
        label: '藻类',
        total: 0,
        handle: 'ALGA_BATCH_SEE',
      },
    ],
  }),
  mutations: {
    [produce_mutations.ACTIVE_TAB](state, name) {
      state.tabActive = name
    },
    [produce_mutations.UPDATE_TABDATA](state, { name, target, value }) {
      const idx = state.tabData.map((item) => item.name).indexOf(name)
      state.tabData[idx][target] = value
    },
  },
}

export default produce

export const purchaseBase = (item = {}) => [
  {
    name: 'purchaseAdd',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/purchase/purchaseAdd.vue'
      ),
    meta: {
      title: '新增采购',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
      key: item['key'],
      goodskeyList: item['goodskeyList'],
    },
  },
  {
    name: 'purchase',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/purchase/purchase.vue'
      ),
    meta: {
      title: '采购管理',
    },
    query: {
      filters: item['filters'],
    },
  },
  {
    name: 'purchaseDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/purchase/purchaseDetail.vue'
      ),
    meta: {
      title: '采购详情',
    },
    query: {
      key: item['key'],
    },
  },

  {
    name: 'purchaseGoodsDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/purchase/purchaseGoodsDetail.vue'
      ),
    meta: {
      title: '物品详情',
    },
    query: {
      key: item['key'],
      goods_key: item['goods_key'],
    },
  },
];

const transport = {
  state: {
    transStatus: {
      1: '空闲',
      2: '已安排',
      3: '运输中',
    },
  },
}

export default transport

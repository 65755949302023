export const transportBase = (item = {}) => [
  {
    name: 'transport',
    component: () =>
      import(/* webpackChunkName: "transport" */ '@/views/transport/index.vue'),
    meta: {
      title: '运输管理',
      activeKeepAlive: true, // 批次也要用到
    },
    params: {
      tab: item['tab'],
    },
    query: {
      lm_id: item['lm_id'],
    },
  },
  {
    name: 'transDetail',
    component: () =>
      import(
        /* webpackChunkName: "transport" */ '@/views/transport/transDetail.vue'
      ),
    meta: {
      title: '运输详情',
    },
    query: {
      ha_key: item['ha_key'],
      trip_type: item['trip_type'],
    },
  },
  {
    name: 'carEdit',
    component: () =>
      import(
        /* webpackChunkName: "transport" */ '@/views/transport/carEdit.vue'
      ),
    meta: {
      title: '车辆编辑',
    },
    query: {
      car_key: item['car_key'],
    },
  },
  {
    // 运输信息编辑
    name: 'transEdit',
    component: () =>
      import(
        /* webpackChunkName: "transport" */ '@/views/transport/transEdit.vue'
      ),
    meta: {
      title: '运输记录编辑',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      ha_key: item['ha_key'],
      lo_key: item['lo_key'],
      type: item['type'], // 1-车运 2-空运
    },
  },
]

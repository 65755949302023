import { get } from '@/utils/http';

const basic = {
  // namespaced: true,
  state: {
    getUserInfoLoading: false,
    pending: new Map(),
    fromUrl: {}, // 上一个路由信息
    directBack: false, // 是否直接返回
    cached: [], // 路由缓存
    userInfo: {}, // 用户信息
    account: undefined, // 手机号
    nickname: undefined, // 昵称
    comp_key: undefined, // 公司key
    comp_name: undefined, // 公司名
    icon: undefined, // 头像
    // 该字段弃用
    role: undefined, // 角色 // 用户类型1公司管理层 2销售管理层 3销售 4生产管理层 5生产员工 6无权限
    roles: [],
    roleName: undefined, // 角色名
    uid: undefined,
    user_key: undefined,
    orderApprove: undefined, // 审核状态
    roleObj: {
      1: '管理员',
      2: '销售',
      3: '场长',
      4: '幼体负责人',
      5: '育苗',
      6: '藻类',
      7: '种虾',
      8: '质检',
      9: '采购',
      10: '仓管',
      11: '品控',
      12: '运输负责人',
      13: '员工',
      14: '行政',
      15: '销售助理',
    },

    // 客户反馈
    feedback: {
      1: '到货反馈',
      2: '养殖反馈',
    },
    batchStatus: {
      // 批次状态
      1: '待投放',
      2: '养殖中',
      3: '部分出苗', // 也是养殖中
      4: '已出苗',
    },
    batchStatusLabels: [
      {
        status: '1',
        value: '待投放',
        color: '#7AC756',
      },
      {
        status: '2',
        value: '养殖中',
        color: '#E29836',
      },
      {
        status: '3',
        value: '部分出苗',
        color: '#E29800',
      },
      {
        value: '已完结',
        status: '4',
        color: '#10101051',
      },
    ],
    // 种虾状态
    sBatchStatus: {
      1: '待接虾',
      2: '养殖中',
      3: '已淘汰',
    },
    sBatchStatusLabels: [
      {
        status: '1',
        value: '待接虾',
        color: '#7AC756',
      },
      {
        status: '2',
        value: '养殖中',
        color: '#E29836',
      },
      {
        value: '已淘汰',
        status: '3',
        color: '#10101051',
      },
    ],
    batchType: {
      1: '幼苗',
      2: '外调苗',
    },
    // 订单状态
    orderStatus: {
      1: '待关联',
      2: '已关联',
      3: '已出苗',
    },
    orderStatusColor: {
      1: 'blue',
      2: 'green',
      3: 'purple',
    },
    // 基地状态
    farmStatus: {
      1: '空置',
      2: '养殖中',
      3: '关闭',
    },
    // 基地类型
    farmType: {
      1: '合作场',
      2: '自营场',
      3: '代加工',
      4: '标粗场',
    },
    // 基地类型
    sBaseType: {
      1: '合作',
      2: '自营',
      3: '代加工',
      4: '标粗',
    },
    poolStatusObj: {
      1: '准备中',
      2: '养殖中',
      3: '空置',
    },
    inputTypeObj: {
      1: '文字',
      2: '图片',
      3: '时间',
      4: '人员',
      5: '数字',
      6: '选择项',
    },
    // 育苗状态
    larvaStatus: {
      2: '待关联',
      3: '待投放',
      4: '养殖中',
      5: '已完成',
    },
    phase: {
      1: 'z1',
      2: 'z2',
      3: 'z3',
      4: 'M1',
      5: 'M2',
      6: 'M3',
      7: 'P1',
      8: 'P2',
      9: 'P3',
      10: 'P4',
      11: 'P5',
      12: 'P6',
      13: 'P7',
      14: 'P8',
      15: 'P9',
      16: 'P10',
    },
    // 反馈-苗活性
    feedbackActivity: {
      1: '良好',
      2: '稳定',
      3: '较差',
      4: '很差',
    },
    // 反馈-质检
    feedbackTest: {
      1: '正常',
      2: '异常',
    },
    // 反馈-长势
    feedbackTrend: {
      1: '正常',
      2: '快',
      3: '慢',
      4: '很差',
    },
    // 成本类别
    costType: {
      1: '育苗成本',
      2: '加工费',
      3: '技术费',
      4: '运费',
      5: '动保费',
      6: '饵料费',
      7: '饲料费',
      8: '其他',
    },
    // 排苗类型
    cleanType: {
      1: '质量原因',
      2: '销售原因',
    },
    // 通知优先级
    noticeLevel: {
      1: '非常紧急',
      2: '紧急',
      3: '普通',
      4: '较低',
      5: '需求',
      6: '最后',
    },
    testType: {
      1: '质检',
      2: '投喂',
    },
    // 车间类型
    wsType: {
      1: '育苗',
      2: '种虾',
      3: '藻类',
      4: '丰年虫',
    },
    // 养殖模式
    pattern: {
      1: '外塘',
      2: '高位池',
      3: '工厂化',
      4: '其他',
    },
    // goodsList: JSON.parse(localStorage.getItem('goodsList')) || [],
    routes: [], // 存储路由对象
    // routeNames: [],  // 存储路由name或path，用来判断浏览器是前进还是后退
  },
  getters: {
    IS_ROLE: (state) => (roles) => {
      if (!Array.isArray(roles)) return false;
      return state.roles.some((item) => roles.indexOf(item) !== -1);
    },
    IS_BACK: (state) => state.directBack,
  },
  mutations: {
    // 对指定组件进行动态更改缓存（缓存）--组件调用该方法时，判断该组件是否存在于该缓存数组，无则添加
    iskeepAlive(state, component) {
      !state.cached.includes(component) && state.cached.push(component);
    },
    // 对指定组件进行动态更改缓存（不缓存）--组件调用该方法时，从缓存数组中删除对应的组件元素
    noKeepAlive(state, component) {
      let index = state.cached.indexOf(component);
      index > -1 && state.cached.splice(index, 1);
    },

    setUserInfoLoading: (state, bool) => {
      state.getUserInfoLoading = bool;
    },

    setUserInfo: (state, userInfo) => {
      state.userInfo = userInfo;
      state.account = userInfo && userInfo['account'];
      if (state.account) {
        localStorage['yiyu-mc-phone'] = state.account;
      }
      state.comp_key = userInfo && userInfo['comp_key'];
      state.comp_name = userInfo && userInfo['comp_name'];
      state.icon = userInfo && userInfo['icon'];
      state.role = userInfo && Number(userInfo['role']);

      state.roles = [];
      if (Array.isArray(userInfo && userInfo['roles'])) {
        userInfo['roles'].forEach((item) => {
          state.roles.push(item.role);
        });
      }

      state.roleName = state.roleObj[state.role];
      state.nickname = userInfo && userInfo['nickname'];
      state.user_key = userInfo && userInfo['user_key'];
      state.uid = userInfo && userInfo['uid'];
    },
    setFromUrl: (state, fromUrl) => {
      state.fromUrl = fromUrl;
    },
    setRoutes: (state, obj) => {
      switch (obj.type) {
        case 'go':
          {
            let n = Number(obj.route);
            while (n < 0) {
              state.routes.pop();
              n++;
            }
          }
          break;
        case 'replace':
          state.routes.pop();
          state.routes.push(obj.route);
          // state.routeNames.push(obj.route.name || obj.route.path);
          break;
        case 'push':
          state.routes.push(obj.route);
          break;
      }
      // console.log("路由栈", state.routes);
    },
    setPending: (state, pending) => {
      state.pending = pending;
    },
    setDirectBack: (state, bool) => {
      state.directBack = bool;
    },
  },
  actions: {
    // 通过store.dispatch('XXXX')分发action
    // 获取用户信息
    getInfo({ state, commit }) {
      if (state.getUserInfoLoading) {
        // 避免连续请求
        return Promise.resolve({
          msg: 'go',
        }); // 放弃请求直接通过
      }
      commit('setUserInfoLoading', true);
      return new Promise((resolve, reject) => {
        get('/user/getInfo', {})
          .then((response) => {
            commit('setUserInfo', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            commit('setUserInfoLoading', false);
          });
      });
    },
  },
};

export default basic;

export const memberBase = (item = {}) => [
  {
    name: 'memberList',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/more/member/list.vue'),
    meta: {
      title: '成员管理',
      keepAlive: true,
    },
  },
  {
    name: 'memberEdit',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/more/member/edit.vue'),
    meta: {
      title: '编辑成员',
    },
  },
  {
    name: 'memberInvite',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/more/member/invite.vue'),
    meta: {
      title: '邀请同事',
    },
  },
  {
    name: 'memberInfo',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/more/member/info.vue'),
    meta: {
      title: '成员信息',
    },
    query: {
      userKey: item['user_key'],
    },
  },
  {
    name: 'memberAccept',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/more/member/accept.vue'),
    meta: {
      title: '接受邀请',
    },
    noToken: true,
  },
]

export const returnGoodsBase = (item = {}) => [
  {
    name: 'returnGoodsAdd',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/returnGoods/returnGoodsAdd.vue'
      ),
    meta: {
      title: '新增退货',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
      sr_key: item['sr_key'],
      type: item['type'],
    },
  },
  {
    name: 'returnGoods',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/returnGoods/returnGoods.vue'
      ),
    meta: {
      title: '退货管理',
    },
    query: {
      filters: item['filters'],
    },
  },
  {
    name: 'returnGoodsDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/returnGoods/returnGoodsDetail.vue'
      ),
    meta: {
      title: '退货详情',
    },
    query: {
      key: item['key'],
    },
  },

  {
    name: 'returnGoodsExamine',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/returnGoods/examine.vue'
      ),
    meta: {
      title: '审核界面',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
      key: item['key'],
      type: item['type'],
      edit_flag: item['edit_flag'],
    },
  },

  {
    name: 'returnGoodsGoodsDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */
        '@/views/store/application/returnGoods/returnGoodsGoodsDetail.vue'
      ),
    meta: {
      title: '物品详情',
    },
    query: {
      sr_key: item['sr_key'],
      goods_key: item['goods_key'],
    },
  },
];

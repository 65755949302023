export const allotBase = (item = {}) => [
  {
    name: 'allotAdd',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/allot/allotAdd.vue'
      ),
    meta: {
      title: '新增调拨',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
    },
  },
  {
    name: 'allot',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/allot/allot.vue'
      ),
    meta: {
      title: '调拨管理',
    },
    query: {
      filters: item['filters'],
    },
  },
  {
    name: 'allotDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/allot/allotDetail.vue'
      ),
    meta: {
      title: '调拨详情',
    },
    query: {
      key: item['key'],
    },
  },

  {
    name: 'allotGoodsDetail',
    component: () =>
      import(
        /* webpackChunkName: "store" */ '@/views/store/application/allot/allotGoodsDetail.vue'
      ),
    meta: {
      title: '物品详情',
    },
    query: {
      key: item['key'],
      goods_key: item['goods_key'],
    },
  },
];

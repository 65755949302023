import { orderBase } from './orderRouters'
import { clientBase } from './clientRoutes'

export const salesBase = (item = {}) => [
  {
    name: 'FF_sales',
    component: () => import(/* webpackChunkName: "sales" */ '@/views/sales'),
    meta: {
      title: '销售',
      keepAlive: true,
    },
  },
  ...orderBase(item),
  ...clientBase(item),
]

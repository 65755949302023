export const fairyBase = (item = {}) => [
  {
    name: 'fairyMgt',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/fairy/fairyMgt.vue'),
    meta: {
      title: '丰年虫管理',
    },
  },
  {
    name: 'WSEdit',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/fairy/WSEdit.vue'),
    meta: {
      title: '丰年虫',
      activeKeepAlive: true,
    },
  },
  {
    name: 'editFairy',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/fairy/editFairy.vue'),
    meta: {
      title: '放虫',
    },
    query: {
      f_key: item['f_key'],
      type: item['type'],
      isEdit: item['isEdit'],
    },
  },
  {
    name: 'fairyDetail',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/fairy/fairyDetail.vue'),
    meta: {
      title: '丰年虫详情',
    },
    query: {
      f_key: item['f_key'],
    },
  },
]

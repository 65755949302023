// 存放 页面内 权限
// 管理员有全部权限，不在考虑范围内

import { staticRouters } from '@/router';
import asyncRouters from '@/router/index';
import store from '../index';

// 通过是否有加赞页面来判断是否展示入口
// IS_HANDLE > IS_PAGE (因为很有可能出现，一个页面有的情况有权限访问，有的情况没权限访问)

const permit = {
  state: {
    isInit: false, // 是否请求过动态路由
    routers: [], // 动态加载的路由
    removeRouters: [], // 要移除的路由
    removeHandles: [], // 要移除的操作
    conf: [
      {
        title: '首页',
        module: 'home', // 模块名
        name: 'home',
        children: [
          {
            title: '日程',
            router: 'FF_home', // 路由名
            name: 'schedule',
            handle: 'HOME_SCHEDULE',
            role: [3, 4, 11, 12, 15], // 包含即展示，只支持数字类型
            // 路由页面
            // router + role 控制页面是否加载，默认都加载
            // 支持数组/字符串 undefiend表示控制的是单单是入口不是页面
            children: [
              {
                title: '出苗信息',
                role: [3, 4, 11, 12, 15],
                handle: 'HOME_SCHEDULE_OUT',
              },
            ],
          },
          {
            title: '总览',
            role: [],
            name: 'data',
            handle: 'HOME_DATA',
            router: 'FF_home',
          },
          {
            title: '计划',
            role: [],
            handle: 'HOME_PLAN',
            router: 'FF_home',
          },
          {
            title: '育苗',
            role: [3, 4, 11],
            handle: 'HOME_BATCH',
            router: 'FF_home',
          },
          {
            title: '订单',
            role: [15],
            handle: 'HOME_ORDER',
            router: 'FF_home',
          },
          {
            title: '关联',
            role: [],
            handle: 'HOME_RELE',
            router: 'FF_home',
          },
        ],
      },
      {
        module: 'sale',
        title: '销售',
        name: 'sale',
        children: [
          {
            title: '查看客户',
            name: 'see',
            router: ['clientMgt', 'clientDetail'],
            role: [2, 15],
            module: 'client',
            handle: 'SALE_CLIENT_SEE',
          },
          {
            title: '新增客户',
            name: 'add',
            router: 'clientAdd',
            role: [2, 15],
            module: 'client',
            children: [
              {
                title: '选择归属销售',
                role: [],
                handle: 'SALE_CLIENT_OWNER',
              },
            ],
            handle: 'SALE_CLIENT_ADD',
          },
          {
            title: '编辑客户',
            name: 'edit',
            router: 'clientAdd',
            role: [2, 15],
            handle: 'SALE_CLIENT_EDIT',
          },
          {
            title: '删除客户',
            name: 'del',
            router: undefined,
            role: [2, 15],
            module: 'client',
            handle: 'SALE_CLIENT_DEL',
          },
          {
            title: '查看订单',
            name: 'see',
            router: ['FF_sales', 'salesDetail'],
            role: [2, 15],
          },
          {
            title: '新增订单',
            role: [2, 15],
            router: 'salesAdd',
            handle: 'SALE_ORDER_ADD',
          },
          {
            title: '编辑订单',
            role: [2, 15],
            router: 'salesAdd',
            handle: 'SALE_ORDER_EDIT',
          },
          {
            title: '删除订单',
            role: [2, 15],
            router: undefined,
            handle: 'SALE_ORDER_DEL',
          },
        ],
      },
      // 更多
      {
        module: 'more',
        title: '更多',
        name: 'more',
        children: [
          {
            title: '自定义配置',
            name: 'setting',
            router: ['setting'],
            role: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            children: [
              {
                title: '种虾',
                handle: 'MORE_SETTING_SEED',
                role: [3, 4, 7],
                router: ['confSet'],
                children: [
                  {
                    title: '种虾别名',
                  },
                  {
                    title: '种虾名称',
                  },
                  {
                    title: '种虾品系',
                  },
                  {
                    title: '投喂配置',
                  },
                  {
                    title: '投喂指标类别',
                  },
                ],
              },
              {
                title: '育苗',
                handle: 'MORE_SETTING_LARVA',
                role: [3, 4, 5, 11, 12],
                children: [
                  {
                    title: '成活率',
                    router: 'surivalRateSet',
                    handle: 'MORE_SETTING_LARVA_SURIVAL',
                    role: [3, 4, 5],
                  },
                  {
                    title: '实结率',
                    router: 'settlementSet',
                    handle: 'MORE_SETTING_LARVA_SETTLE',
                    role: [3, 4, 5],
                  },
                  {
                    title: '育苗天数',
                    router: 'breedDaySet',
                    handle: 'MORE_SETTING_LARVA_BREED',
                    role: [3, 4, 5],
                  },
                  {
                    title: '印刷袋',
                    router: 'bagSet',
                    handle: 'MORE_SETTING_LARVA_BAG',
                    role: [3, 4, 5],
                  },
                  {
                    title: '投喂配置',
                    router: 'techMgt',
                    handle: 'MORE_SETTING_LARVA_TECHCONF',
                    role: [3, 4, 5],
                  },
                  {
                    title: '投喂指标类别',
                    router: 'confSet',
                    handle: 'MORE_SETTING_LARVA_TECHTYPE',
                    role: [3, 4, 5],
                  },
                  {
                    title: '阶段',
                    router: 'confSet',
                    role: [3, 4, 5],
                    handle: 'MORE_SETTING_LARVA_PHASE',
                  },
                  {
                    title: '来源',
                    router: 'supplierSet',
                    role: [3, 4, 5],
                    handle: 'MORE_SETTING_LARVA_SUPPLIER',
                  },
                  {
                    title: '运输行程',
                    router: 'transportSet',
                    role: [4, 11, 12],
                    handle: 'MORE_SETTING_LARVA_TRANSPORT',
                  },
                  {
                    title: '联系人',
                    router: 'contactSet',
                    role: [4, 11, 12],
                    handle: 'MORE_SETTING_LARVA_CONTACT',
                  },
                ],
                router: [
                  'confSet',
                  'surivalRateSet',
                  'settlementSet',
                  'breedDaySet',
                  'bagSet',
                  'nodeSet',
                  'transportSet',
                ],
              },
              {
                title: '藻类',
                handle: 'MORE_SETTING_ALGA',
                role: [3, 6],
                router: ['confSet'],
                children: [
                  {
                    title: '藻类来源',
                  },
                  {
                    title: '品种',
                  },
                  {
                    title: '容器管理',
                  },
                ],
              },
              {
                title: '销售',
                handle: 'MORE_SETTING_SALE',
                role: [2, 15],
                router: ['regionSet', 'orderSet', 'contactSet'],
                children: [
                  {
                    title: '订单默认需求',
                  },
                  {
                    title: '客户区域',
                  },
                ],
              },
              {
                title: '质检',
                handle: 'MORE_SETTING_DECT',
                router: ['confSet'],
                role: [3, 8],
                children: [
                  {
                    title: '样本类型',
                  },
                  {
                    title: '质检配置',
                  },
                  {
                    title: '指标类别',
                  },
                ],
              },
              {
                title: '库存',
                handle: 'MORE_SETTING_STORE',
                role: [9, 10],
                router: ['confSet'],
                children: [
                  {
                    title: '物品类别',
                    role: [9],

                    handle: 'MORE_SETTING_STORE_GOODS_TYPE',
                  },
                  {
                    role: [9],
                    title: '供应商',

                    handle: 'MORE_SETTING_STORE_SUPPLIER',
                  },
                  {
                    role: [9],
                    title: '采购审核人',

                    handle: 'MORE_SETTING_STORE_EXAMINE_MAN',
                  },
                  {
                    role: [9, 10],
                    title: '申请部门',

                    handle: 'MORE_SETTING_STORE_SECTION',
                  },
                ],
              },
            ], // 页面内的操作
          },
        ],
      },
      // 育苗
      {
        module: 'larva',
        title: '育苗',
        children: [
          {
            title: '查看基地',
            name: 'see', // undefined则默认用keyName，e.g.这里是see
            router: ['farmMgt', 'farmDetail'],
            handle: 'LARVA_FARM_SEE',
            role: [3, 4, 5, 11],
            module: 'farm',
          },
          {
            title: '新增基地',
            name: 'edit',
            router: 'farmEdit',
            role: [3, 4, 5, 11],
            module: 'farm',
          },
          {
            title: '编辑基地',
            name: 'edit',
            router: 'farmEdit',
            role: [3, 4, 5, 11],
            module: 'farm',
          },
          {
            title: '删除基地',
            name: 'del',
            router: undefined,
            role: [3, 4, 5, 11],
            handle: 'LARVA_FARM_DEL',
            module: 'farm',
          },
          {
            title: '育苗批次详情',
            name: 'see',
            router: ['FF_produce', 'larvaProduct'],
            role: [3, 4, 5, 11],
            handle: 'LARVA_BATCH_SEE',
            module: 'batch',
            children: [
              {
                title: '质检',
                router: ['dectEdit'],
                role: [3, 4, 5, 11],
                handle: 'LARVA_BATCH_DECT',
              },
              {
                title: '投喂',
                router: ['techEdit'],
                role: [3, 5],
                handle: 'LARVA_BATCH_CUL',
              },
              {
                title: '估苗',
                router: ['estimateEdit', 'estimate'],
                role: [3, 4, 5, 11],
                handle: 'LARVA_BATCH_ESTIMATE',
              },
              {
                title: '搬苗',
                router: ['FF_produce'],
                role: [3, 4, 5, 11],
                handle: 'LARVA_BATCH_MOVE',
                children: [
                  {
                    title: '删除',
                    role: [3, 4, 5, 11],
                    handle: 'LARVA_BATCH_MOVE_DEL',
                  },
                ],
              },
              {
                title: '排苗',
                router: ['remove', 'removeEdit'],
                role: [3, 4, 5, 11],
                handle: 'LARVA_BATCH_REMOVE',
                children: [
                  {
                    title: '删除',
                    role: [3, 4, 5, 11],
                    handle: 'LARVA_BATCH_REMOVE_DEL',
                  },
                ],
              },
              {
                title: '处理',
                router: ['deal', 'dealEdit'],
                role: [3, 4, 5, 11],
                handle: 'LARVA_BATCH_DEAL',
                children: [
                  {
                    title: '删除',
                    role: [3, 4, 5, 11],
                    handle: 'LARVA_BATCH_DEAL_DEL',
                  },
                ],
              },
              {
                title: '取消关联',
                role: [3, 4, 11],
                handle: 'LARVA_BATCH_RElE_CANCEL',
              },
              {
                title: '出苗',
                router: ['outEdit', 'out'],
                role: [3, 4, 5, 11],
                handle: 'LARVA_BATCH_OUT',
                children: [
                  {
                    title: '新增出苗',
                    role: [3, 4, 5, 11],
                    handle: 'LARVA_BATCH_OUT_ADD',
                  },
                  {
                    title: '编辑出苗',
                    role: [3, 4, 5, 11],
                    handle: 'LARVA_BATCH_OUT_EDIT',
                  },
                  {
                    title: '删除出苗',
                    role: [3, 4, 11],
                    handle: 'LARVA_BATCH_OUT_DEL',
                  },
                ],
              },
              {
                title: '完结',
                role: [],
                handle: 'LARVA_BATCH_OVER',
              },
            ],
          },
          {
            title: '外调苗',
            role: [3, 4, 11],
            router: ['larvaImport'],
            handle: 'LARVA_BATCH_IMPORT',
          },
          {
            title: '新增育苗',
            role: [4],
            handle: 'LARVA_BATCH_ADD',
            router: ['larvaLaunch'],
          },
          {
            title: '编辑育苗',
            role: [4],
            handle: 'LARVA_BATCH_EDIT',
            router: ['larvaLaunch'],
          },
          {
            title: '删除育苗',
            role: [4],
            handle: 'LARVA_BATCH_DEL',
            router: undefined,
          },
          {
            title: '育苗投放',
            role: [4],
            children: [
              {
                title: '新增',
                role: [4],
                router: 'larvaLaunch',
                handle: 'LARVA_BATCH_LAUNCH_ADD',
              },
              {
                title: '编辑',
                role: [4],
                router: 'larvaLaunch',
              },
              {
                title: '删除',
                role: [4],
                handle: 'LARVA_BATCH_DEL',
                router: undefined,
              },
            ],
          },
          {
            title: '新增藻类需求',
            role: [3, 4, 5, 11],
            router: ['larvaRequirementEdit'],
          },
        ],
      },
      // 种虾
      {
        module: 'seed',
        title: '种虾',
        children: [
          {
            title: '安排幼体',
            role: [3, 4, 7, 15],
            handle: 'SEED_BATCH_LARVA',
            children: [
              {
                title: '查看',
                role: [3, 4, 7, 15],
                router: 'sArrangeList',
                handle: 'SEED_BATCH_LARVA_SEE',
              },
              {
                title: '新增',
                role: [3, 4],
                handle: 'SEED_BATCH_LARVA_ADD',
                router: 'larvaLaunch',
              },
              {
                title: '编辑',
                role: [3, 4],
                handle: 'SEED_BATCH_LARVA_EDIT',
                router: 'larvaLaunch',
              },
              {
                title: '删除',
                role: [3, 4],
                handle: 'SEED_BATCH_LARVA_DEL',
              },
            ],
          },
          {
            title: '排幼体',
            router: 'larvaClear',
            role: [3, 7, 4],
            handle: 'SEED_LARVA_CLEAR',
          },
          {
            title: '查看排幼体',
            role: [3, 4, 7, 15],
            handle: 'SEED_LARVA_CLEAR_SEE',
          },
          {
            title: '查看基地',
            name: 'see', // undefined则默认用keyName，e.g.这里是see
            router: ['farmMgt', 'farmDetail'],
            handle: 'SEED_FARM_SEE',
            role: [3, 4, 7], // 包含即展示，数字类型
            module: 'farm',
          },
          {
            title: '新增基地',
            name: 'edit',
            router: 'farmEdit',
            role: [3, 4, 7], // 包含即展示，数字类型
            module: 'farm',
          },
          {
            title: '编辑基地',
            name: 'edit',
            router: 'farmEdit',
            role: [3, 4, 7], // 包含即展示，数字类型
            module: 'farm',
          },
          {
            title: '删除基地',
            name: 'del',
            router: undefined,
            role: [3, 4, 7], // 包含即展示，数字类型
            handle: 'SEED_FARM_DEL',
            module: 'farm',
          },
          {
            title: '查看种虾',
            name: 'see',
            router: ['FF_produce', 'sBatchDetail'],
            handle: 'SEED_BATCH_SEE',
            role: [3, 4, 7], // 包含即展示，数字类型
            module: 'batch',
            children: [
              {
                title: '投喂',
                router: ['techEdit'],
                role: [3, 7],
                handle: 'SEED_BATCH_CUL',
              },
              {
                title: '质检申请',
                role: [3, 4, 7],
                handle: 'SEED_BATCH_DECT',
              },
            ],
          },
          {
            title: '安排种虾',
            role: [3, 4, 7],
            handle: 'SEED_BATCH_SCHEDULE',
            children: [
              {
                title: '新增种虾',
                role: [3, 4],
                handle: 'SEED_BATCH_ADD',
                router: 'sBatchEdit',
              },
              {
                title: '编辑种虾',
                role: [3, 4],
                router: 'sBatchEdit',
                handle: 'SEED_BATCH_EDIT',
              },
              {
                title: '删除种虾',
                role: [3, 4],
                handle: 'SEED_BATCH_DEL',
                router: undefined,
              },
            ],
          },
          {
            title: '接虾',
            role: [3, 4, 7],
            router: 'sBatchShrimpEdit',
            handle: 'SEED_BATCH_SHRIMP',
            children: [
              {
                title: '编辑接虾',
                role: [3, 4, 7],
                router: 'sBatchShrimpEdit',
                handle: 'SEED_BATCH_SHRIMP_EDIT',
              },
              {
                title: '删除接虾',
                role: [3, 4, 7],
                router: undefined,
                handle: 'SEED_BATCH_SHRIMP_DEL',
              },
            ],
          },
          {
            title: '每日记录',
            handle: 'SEED_BATCH_DAILY',
            role: [3, 4, 7],
            router: ['sDailyList', 'sDailyEdit', 'sDailyDetail', 'dailyFeed'],
          },
        ],
      },
      // 藻种
      {
        module: 'ALGA',
        title: '藻类',
        children: [
          {
            title: '查看基地',
            name: 'see', // undefined则默认用keyName，e.g.这里是see
            router: ['farmMgt', 'algaeDetail'],
            handle: 'ALGA_FARM_SEE',
            role: [3, 6], // 包含即展示，数字类型
            module: 'farm',
          },
          {
            title: '新增基地',
            name: 'edit',
            router: 'farmEdit',
            role: [3, 6], // 包含即展示，数字类型
            module: 'farm',
          },
          {
            title: '编辑基地',
            name: 'edit',
            router: 'farmEdit',
            role: [3, 6], // 包含即展示，数字类型
            module: 'farm',
          },
          {
            title: '删除基地',
            name: 'del',
            router: undefined,
            role: [3, 6], // 包含即展示，数字类型
            handle: 'ALGA_FARM_DEL',
            module: 'farm',
          },
          {
            title: '查看藻种',
            name: 'see',
            router: ['FF_produce', 'algaListDetail'],
            role: [3, 6], // 包含即展示，数字类型
            module: 'batch',
            children: [],
            handle: 'ALGA_BATCH_SEE',
          },
          {
            title: '新增藻种',
            router: 'algaEdit',
            role: [3, 6],
            module: 'batch',
          },
          {
            title: '编辑藻种',
            router: 'algaEdit',
            role: [3, 6],
            module: 'batch',
          },
          {
            title: '删除藻种',
            role: [3, 6],
            module: 'batch',
            handle: 'ALGA_BATCH_DEL',
            router: undefined,
          },
          {
            title: '调整',
            role: [3, 6],
            module: 'batch',
            handle: 'ALGA_BATCH_ADJUST',
            router: ['algaAdjust'],
          },
          {
            title: '淘汰',
            role: [3, 6],
            module: 'batch',
            handle: 'ALGA_BATCH_OUT',
            router: ['algaOut'],
          },
          {
            title: '容器管理',
            role: [3, 6],
            router: ['containerEdit', 'containerList'],
          },
          {
            title: '分配管理',
            role: [3, 6],
            router: [
              'algaDistributeRecord',
              'algaDistributeRecordDetail',
              'algaDistribute',
            ],
          },
        ],
      },
      {
        title: '成员管理',
        module: 'member',
        children: [
          {
            title: '入口',
            role: [],
            router: ['memberList'],
          },
          {
            title: '邀请成员',
            role: [],
            router: 'memberInvite',
          },
          {
            title: '编辑成员',
            name: 'edit',
            children: [
              {
                title: '角色',
                role: [],
                handle: 'MEMBER_ROLE_EDIT',
              },
              {
                title: '在职状态',
                role: [],
                handle: 'MEMBER_STATUS_EDIT',
              },
              {
                title: '入职时间',
                role: [],
                handle: 'MEMBER_ENTRY_EDIT',
              },
              {
                title: '负责基地',
                role: [],
                handle: 'MEMBER_FARM_EDIT',
              },
            ],
          },
          {
            title: '删除成员',
            role: [],
            handle: 'MEMBER_DEL',
          },
        ],
      },
      {
        title: '质检管理',
        module: 'dect',
        children: [
          {
            title: '质检配置',
            role: [3, 4, 8, 11],
            router: ['dectTemplateEdit', 'dectConfEdit', 'dectSet'],
            module: 'conf',
            children: [
              {
                title: '编辑',
                role: [3, 4, 8, 11],
              },
              {
                title: '删除',
                role: [3, 4, 8, 11],
                handle: 'DECT_CONF_DEL',
              },
            ],
          },
          {
            title: '质检管理入口',
            router: ['dectMgt'],
            role: [3, 4, 8, 11],
          },
          {
            title: '质检申请',
            name: 'apply',
            role: [3, 4, 5, 6, 7, 8, 11],
            children: [
              {
                title: '查看',
                role: [3, 4, 5, 6, 7, 8, 11],
                router: ['dectApplyList', 'dectDetail'],
              },
              {
                title: '新增',
                role: [3, 4, 5, 6, 7, 8, 11],
                router: 'dectApplyEdit',
                handle: 'DECT_APPLY_ADD',
              },
              {
                title: '编辑',
                role: [3, 4, 5, 6, 7, 8, 11],
                router: 'dectApplyEdit',
              },
              {
                title: '删除(没有做)',
                role: [3, 4, 5, 6, 7, 8, 11],
                handle: 'DECT_APPLY_DEL',
              },
            ],
          },
          {
            title: '质检报告',
            role: [3, 4, 8, 11],
            router: 'dectDetail',
          },
          {
            title: '新增/编辑质检',
            role: [3, 4, 8, 11],
            router: 'dectEdit',
          },
          {
            title: '删除质检',
            role: [3, 4, 8, 11],
          },
        ],
      },
      {
        title: '操作记录',
        module: 'record',
        children: [
          {
            title: '种虾操作',
            router: ['techList'],
            role: [3, 4, 7],
            handle: 'RECORD_SEED',
          },
          {
            title: '育苗操作',
            router: ['techList', 'recordDetail', 'recordList'],
            role: [3, 4, 5, 11],
            handle: 'RECORD_LARVA',
          },
          {
            title: '藻类操作',
            router: ['recordDetail', 'recordList'],
            role: [3, 4, 6],
            handle: 'RECORD_ALGA',
          },
          {
            title: '质检操作',
            role: [3, 4, 8, 11],
            handle: 'RECORD_DECT',
          },
        ],
      },

      {
        title: '考勤',
        role: undefined,
        children: [
          {
            title: '打卡',
            router: ['attendanceClock'],
          },
          {
            title: '申请',
            router: ['attendanceApply'],
          },
          {
            title: '统计',
            router: ['attendanceStat'],
            children: [
              {
                title: '公司统计',
                role: [],
                handle: 'ATTENDANCE_COMP_STAT',
              },
            ],
          },
          {
            title: '设置',
            role: [14],
            router: ['attendanceSet'],
          },
          {
            title: '考勤规则',
            role: [1, 14],
            router: ['attendGroupEdit'],
          },
        ],
      },
      // 库存
      {
        module: 'store',
        title: '库存',
        name: 'store',
        role: undefined,
        router: 'storeMgt',
        children: [
          {
            title: '调拨',
            name: 'allot',
            router: 'allot',
            role: [9, 10],
            handle: 'STORE_ALLOT',
            children: [
              {
                title: '直接入库',
                role: [9],
                handle: 'STORE_ALLOT_IN_STORE',
              },
            ],
          },
          {
            title: '申请列表',
            role: [9, 10],
            handle: 'STORE_APPLY_LIST',
          },
          {
            title: '申请',
            name: 'apply',
            router: 'apply',
            role: undefined,
            children: [
              {
                title: '审核',
                role: [9, 10],
                handle: 'STORE_APPLY_EXAMINE',
              },
              {
                title: '复审',
                role: [9],
                handle: 'STORE_APPLY_EXAMINE_AGAIN',
              },
              {
                title: '仓管审核跳转',
                role: [10],
                handle: 'STORE_APPLY_EXAMINE_JUMP',
              },
              {
                title: '管理员初审',
                role: [],
                handle: 'STORE_APPLY_EXAMINE_ONLY',
              },
            ],
            handle: 'STORE_APPLY',
          },
          {
            title: '盘点',
            name: 'check',
            router: 'check',
            role: [9, 10],
            handle: 'STORE_CHECK',
          },
          {
            title: '出库',
            name: 'delivery',
            router: 'delivery',
            role: [9, 10],
            handle: 'STORE_DELIVERY',
            children: [
              {
                title: '物品详情',
                role: [9],
                handle: 'STORE_DELIVERY_IN_STORE',
              },
            ],
          },
          {
            title: '物品',
            name: 'goods',
            router: 'goods',
            role: [9, 10],
            handle: 'STORE_GOODS',
            children: [
              {
                title: '新增物品',
                router: 'goodsAdd',
                role: [9],
                handle: 'STORE_GOODS_GOODS_ADD',
              },
              {
                title: '物品详情',
                router: 'goodsDetail',
                role: [9, 10],
                handle: 'STORE_GOODS_GOODS_DETAIL',
              },
            ],
          },
          {
            title: '采购',
            role: [9],
            router: 'purchase',
            handle: 'STORE_PURCHASE',
          },
          {
            title: '新增采购',
            role: [1, 9],
            router: 'purchaseAdd',
            handle: 'PURCHASE_ADD'
          },
          {
            title: '入库',
            role: [9, 10],
            router: 'storage',
            handle: 'STORE_STORAGE',
          },
          {
            title: '仓库',
            role: [9, 10],
            router: 'warehouses',
            handle: 'STORE_WAREHOUSES',
            children: [
              {
                title: '新增仓库',
                router: 'warehousesAdd',
                role: [9],
                handle: 'STORE_WAREHOUSES_WAREHOUSES_ADD',
              },

              {
                title: '仓库详情',
                router: 'warehousesDetail',
                role: [9, 10],
                handle: 'STORE_WAREHOUSES_WAREHOUSES_DETAIL',
              },
            ],
          },
          {
            title: '统计',
            role: [9, 10],
            router: 'statistics',
            handle: 'STORE_STATISTICS',
          },
          {
            title: '退货',
            role: [9, 10],
            router: 'returnGoods',
            handle: 'STORE_RETURN_GOODS',
            children: [
              {
                title: '退货审核',
                role: [9],
                handle: 'STORE_RETURN_GOODS_EXAMINE',
              },
            ],
          },
        ],
      },

      {
        title: '通知',
        module: 'notice',
        handle: 'NOTICE',
        children: [
          {
            title: '发布通知',
          },
        ],
      },
      {
        title: '运输管理',
        module: 'transport',
        role: [4, 11, 12],
        router: 'transport',
        children: [
          {
            title: '车辆管理',
            handle: 'TRANSPOERT_CAR',
            role: [4, 11, 12],
            router: ['transport'],
            children: [
              {
                title: '新增',
                handle: 'TRANSPOERT_CAR_EDIT',
                router: 'carEdit',
                role: [4, 11, 12],
              },
              {
                title: '编辑',
                handle: 'TRANSPOERT_CAR_EDIT',
                router: 'carEdit',
                role: [4, 11, 12],
              },
              {
                title: '删除',
                handle: 'TRANSPOERT_CAR_ADD',
                role: [4, 11, 12],
              },
              {
                title: '查看',
                handle: 'TRANSPOERT_CAR_SEE',
                role: [4, 11, 12],
              },
            ],
          },
          {
            title: '车运',
            handle: 'TRANSPORT_S_TRIP',
            router: 'transport',
            role: [4, 11, 12],
            children: [
              {
                title: '新增',
                handle: 'TRANSPORT_S_TRIP_EDIT',
                role: [4, 11, 12],
                router: 'transEdit',
              },
              {
                title: '编辑',
                handle: 'TRANSPORT_S_TRIP_EDIT',
                role: [4, 11, 12],
                router: 'transEdit',
              },
              {
                title: '删除',
                handle: 'TRANSPORT_S_TRIP_DEL',
                role: [],
              },
              {
                title: '查看',
                handle: 'TRANSPORT_S_TRIP_SEE',
                role: [4, 11, 12],
              },
            ],
          },
          {
            title: '空运',
            handle: 'TRANSPORT_L_TRIP',
            router: 'transport',
            children: [
              {
                title: '新增',
                handle: 'TRANSPORT_L_TRIP_EDIT',
                role: [],
                router: 'transEdit',
              },
              {
                title: '编辑',
                handle: 'TRANSPORT_L_TRIP_EDIT',
                role: [],
                router: 'transEdit',
              },
              {
                title: '删除',
                handle: 'TRANSPORT_L_TRIP_DEL',
                role: [],
              },
              {
                title: '查看',
                handle: 'TRANSPORT_L_TRIP_SEE',
                role: [11, 12],
              },
            ],
          },
        ],
      },
    ],
  },
  getters: {
    /**
     * 是否是要加载的路由
     * @param {*} routeName 路由名
     * @returns 不在要移除的路由里，返回true
     */
    IS_PAGE: (state) => (routeName) => {
      return !state.removeRouters.includes(routeName);
    },
    /**
     * 是否是要展示的操作
     * @param {*} handleName 操作标识
     * @returns 不在要移除的操作里，返回true
     */
    IS_HANDLE: (state) => (handleName) => {
      // console.log(!state.removeHandles.includes(handleName));
      return !state.removeHandles.includes(handleName);
    },
    /**
     * 过滤不需要展示的操作
     * @param {arr,obj} data 操作数据
     * @param {*} handleName 操作标识的keyName，默认handle
     * @returns 过滤后的操作数组
     */
    FILTER_HANDLE:
      (state, getters) =>
      (data, keyName = 'handle') => {
        if (Array.isArray(data)) {
          return data.filter((item) => getters.IS_HANDLE(item[keyName]));
        } else {
          let obj = {};
          for (let key in data) {
            if (getters.IS_HANDLE(data[key][keyName])) {
              obj[key] = data[key];
            }
          }
          return obj;
        }
      },
    FILTER_PAGE:
      (state, getters) =>
      (data, keyName = 'page') => {
        if (Array.isArray(data)) {
          return data.filter((item) => getters.IS_PAGE(item[keyName]));
        } else {
          let obj = {};
          for (let key in data) {
            if (getters.IS_PAGE(data[key][keyName])) {
              obj[key] = data[key];
            }
          }
          return obj;
        }
      },
  },
  mutations: {
    setIsInit(state, bool) {
      state.isInit = bool;
    },
    setRouters(state, arr) {
      state.routers = arr;
    },
    setRemoveRouters(state, arr) {
      state.removeRouters = arr;
    },
    setRemoveHandles(state, arr) {
      state.removeHandles = arr;
    },
  },
  actions: {
    // 动态设置路由 此为设置途径
    setRouterList({ commit }, filterRouters) {
      commit('setIsInit', true);
      commit('setRouters', staticRouters.concat(filterRouters)); // 进行路由拼接并存储
    },

    getRouterList({ dispatch, state, commit }) {
      let roles = store.state.basic.roles;
      function getRemoveData(arr, removeRouters, removeHandles, showRouters) {
        if (Array.isArray(arr)) {
          arr.forEach((item) => {
            if (
              item.role &&
              roles.every((item1) => !item.role.includes(item1))
            ) {
              removeHandles.push(item.handle);
              if (Array.isArray(item.router)) {
                removeRouters.push(...item.router);
              } else if (typeof item.router == 'string') {
                removeRouters.push(item.router);
              }
            } else {
              // 有权限
              if (Array.isArray(item.router)) {
                showRouters.push(...item.router);
              } else if (typeof item.router == 'string') {
                showRouters.push(item.router);
              }

              getRemoveData(
                item.children,
                removeRouters,
                removeHandles,
                showRouters
              );
            }
          });
        }
      }

      return new Promise((resolve) => {
        let removeRouters = [];
        let removeHandles = [];
        let showRouters = []; // 如果出现既要加载，又不需要加载的，一律加载
        if (
          !store.state.basic.roles.includes(1) &&
          !store.state.basic.roles.includes('1')
        ) {
          getRemoveData(state.conf, removeRouters, removeHandles, showRouters);
        }

        removeRouters = [...new Set(removeRouters)];
        removeHandles = [...new Set(removeHandles)];
        showRouters = [...new Set(showRouters)];

        showRouters.forEach((router) => {
          let index = removeRouters.indexOf(router);
          if (index != -1) {
            removeRouters.splice(index, 1);
          }
        });
        // console.log('当前角色', store.state.basic.roles)
        // console.log('要移除的路由', removeRouters)
        // console.log('要移除的操作', removeHandles)
        commit('setRemoveRouters', removeRouters);
        commit('setRemoveHandles', removeHandles);

        let filterRouters = asyncRouters.filter((item) => {
          return !removeRouters.includes(item.name) && item.noToken != true;
        });

        filterRouters = filterRouters.concat([
          {
            path: '/404',
            meta: {
              title: '禁止访问',
            },
            component: () =>
              import(/* webpackChunkName: "index" */ '@/views/404.vue'),
          },
          {
            path: '*',
            redirect: '/404',
          },
        ]);

        dispatch('setRouterList', filterRouters);
        resolve(filterRouters);
      });
    },
  },
};

export default permit;

export const setBase = (item = {}) => [
  {
    name: 'attendanceSet',
    component: () =>
      import(
        /* webpackChunkName: "attendance" */ '@/views/attendance/set/index.vue'
      ),
    meta: {
      title: '考勤组管理',
      keepAlive: true,
    },
  },
  {
    name: 'attendGroupEdit',
    component: () =>
      import(
        /* webpackChunkName: "attendance" */ '@/views/attendance/set/attendGroupEdit.vue'
      ),
    meta: {
      title: '考勤组',
      activeKeepAlive: true,
    },
    query: {
      atg_key: item.atg_key,
    },
  },
  {
    name: 'attendGroupDetail',
    component: () =>
      import(
        /* webpackChunkName: "attendance" */ '@/views/attendance/set/attendGroupDetail.vue'
      ),
    meta: {
      title: '考勤规则',
    },
    query: {
      atg_key: item.atg_key,
    },
  },
]

// 路由拦截
import { router, noTokenList } from '@/router'
import store from '@/store'
import { getToken, removeToken } from '../utils/cookie'
import { clearPending } from '@/api/middleware/pending'
import { Toast } from 'vant'
import { isWeiXin } from '@/utils/weixin'
import weixinConf from '@/utils/weixin'

// 去到下面这些页面默认都缓存
const cachedName = [
  'memberInvite',
  'Select',
  'farmEdit',
  'recordDetail',
  'confSet',
  'surivalRateSet',
  'settlementSet',
  'breedDaySet',
  'bagSet',
  'supplierSet',
  'transportSet',
  'contactSet',
]

// 每条路由进去前都要判断下是否有token
router.beforeEach((to, from, next) => {
  // console.log(window.location.href, to, from)

  clearPending()
  if (to.name && store.state.permit.removeRouters.includes(to.name)) {
    if (from.fullPath.indexOf('FF_login') !== -1) {
      next('FF_more')
    } else {
      Toast.fail('您没有该操作权限哦')
      next(false)
    }

    return
  }

  // 主要用来判断post请求后，是否直接放回上一页面
  // this.IS_BACK可以直接获取
  if (to.params?.back) {
    store.commit('setDirectBack', true)
  } else {
    store.commit('setDirectBack', false)
  }

  // 动态缓存
  // 缓存过的只会触发activeted，没缓存过的会同时触发created和activated（因为是先缓存的）
  if (to.meta.activeKeepAlive) {
    // console.log('缓存', to.name)
    store.commit('iskeepAlive', to.name)
  }

  if (to.params?.cached) {
    // 缓存
    // console.log('不移除缓存', from.name)
  } else if (
    // 如果去的界面不是 设置界面 或者 邀请成员 ，就不缓存
    isNotCachedRoute(to.path) &&
    from.meta.activeKeepAlive
  ) {
    // console.log('移除缓存', from.name)
    store.commit('noKeepAlive', from.name)
  }

  const title = to.meta && to.meta.title
  if (title) {
    document.title = title || '苗场管理系统'
  }

  // 不需要token的页面，直接进去
  const flag = noTokenList.some(
    (name) => to.name == name || to.path.includes(name),
  )

  // 如果去的登录页 并且 带token 就不放行
  if (flag && !(isLoginPage(to) && getToken())) {
    next()
  } else if (!getToken()) {
    if (!weiXinDeal(next)) {
      // 重定向到登录页
      next(`/FF_login?redirect=${to.fullPath}`)
    }
  } else {
    if (!store.state.attendance.hasGetInfo) {
      store.dispatch('getAttendInfo')
    }
    // alert('发起请求')
    // alert(window.location.href)
    store
      .dispatch('getInfo')
      .then((res) => {
        if (res && res.code == 10000) {
          if (!store.state.permit.isInit && getToken()) {
            store.dispatch('getRouterList').then((filterRouters) => {
              filterRouters.forEach((route) => {
                // 隐藏bug
                router.addRoute(route)
              })
              // replace: true只是一个设置信息，告诉VUE本次操作后，不能通过浏览器后退按钮，返回前一个路由。
              // hack方法 确保addRoutes已完成
              next({
                ...to,
                replace: true,
              })
            })
          } else {
            // 有token回登录页也要请求，避免token是无效的
            // && !(isLoginPage(to) && getToken())
            // 考虑到app,这一步基本不会成立
            if (isLoginPage(to)) {
              if (to.query && to.query.redirect) {
                next(to.query.redirect)
              } else {
                next('FF_more')
              }
            } else if (to.path == '/' && to.name && to.name != 'FF_more') {
              next(to.name)
            } else {
              next()
            }
          }
        } else {
          if (
            res &&
            (res.msg.indexOf('网络错误') != -1 ||
              res.msg.indexOf('请求超时') != -1)
          ) {
            next()
          } else if (res.msg == 'go') {
            next()
          } else {
            removeToken()
            // location.reload()
            next(`/FF_login?redirect=${to.fullPath}`)
          }
        }
      })
      .catch((err) => {
        console.log('捕获的异常', err)
        if (/Network Error/.test(err.message)) {
          // 网络错误
        } else if (/^timeout\sof\s.+ms\sexceeded$/.test(err.message)) {
          // 超时错误
        } else {
          if (!weiXinDeal()) {
            removeToken()
            // location.reload()
            next(`/FF_login?redirect=${to.fullPath}`)
          }
        }
      })
  }

  // 这里的from，有可能来自于push，也有可能来自于go的
  // store.commit('setFromUrl', from)
})

function isNotCachedRoute(path) {
  return cachedName.every((item) => path.indexOf(item) == -1)
}

function weiXinDeal(next) {
  if (isWeiXin()) {
    if (weixinConf.isCanToken()) {
      // 获取微信授权登录的token
      weixinConf.getNext(next)
    } else {
      // 微信网页授权
      window.location.replace(weixinConf.getUrl(window.location.href))
      next(false)
    }
    return true
  } else {
    // 重定向到登录页
    return false
  }
}

function isLoginPage(to) {
  return to.path?.indexOf('FF_login') != -1 || to.name == 'FF_login'
}

/**
 * 获取路由配置
 * @param {arr} base
 * @param {string} type
 * @returns type = route => 路由导航 / type = jump => 路由跳转要带的参数
 */
function getRouteConf(base = [], type = 'route') {
  if (type == 'route') {
    return base
  } else if (type == 'jump') {
    return base.reduce((obj, cur) => ({ ...obj, [cur.name]: cur }), {})
  }
}

export default getRouteConf

export const techBase = (item = {}) => [
  {
    name: 'techMgt',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/tech/index.vue'),
    meta: {
      title: '管理',
      keepAlive: true,
    },
    query: {
      ws_type: item['ws_type'],
      test_type: item['test_type'],
    },
    params: {
      tab: item['tab'], // 1 - 模板    0 - 指标
    },
  },
  {
    name: 'techEdit',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/tech/edit.vue'),
    meta: {
      title: '记录',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      key: item['r_key'], // 报告key
      mc_key: item['mc_key'],
      lm_id: item['lm_id'], // 育苗批次key
      ss_key: item['ss_key'], // 种虾批次key
      temp_key: item['temp_key'], // 模板key
      ws_type: item['ws_type'],
      test_type: item['test_type'],
      l_key: item['l_key'],
      newest_r_key: item['newest_r_key'],
    },
  },
  {
    name: 'techList',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/tech/list.vue'),
    meta: {
      title: '投喂',
      activeKeepAlive: true,
    },
    query: {
      lm_id: item['lm_id'],
      ws_type: item['ws_type'],
      test_type: item['test_type'],
      ss_key: item['ss_key'],
    },
  },
  {
    name: 'techDetail',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/tech/detail.vue'),
    meta: {
      title: '投喂报告',
    },
    query: {
      key: item['r_key'],
      ws_type: item['ws_type'],
      test_type: item['test_type'],
    },
  },
  {
    name: 'confEdit',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/tech/conf/edit.vue'),
    meta: {
      title: '指标',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      key: item['tt_key'],
      ws_type: item['ws_type'],
      test_type: item['test_type'],
    },
  },
  {
    name: 'templateEdit',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/tech/template/edit.vue'
      ),
    meta: {
      title: '模板',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      key: item['key'], // 模板key
      ws_type: item['ws_type'],
      test_type: item['test_type'],
    },
  },
];

import { get } from '@/utils/http'

function flatten(data) {
  return data.reduce(
    (arr, { name, conver, parent_name, children = [] }) =>
      arr.concat([{ name, conver, parent_name }], flatten(children)),
    [],
  )
}
const goods = {
  state: {
    // 自定义单位
    otherOne: '',
    weightUnit: [],
    volumeUnit: [],
    otherUnit: [],
    store: [],
    warehouses: [],
  },
  mutations: {
    setWeightUnit(state, newUnit) {
      if (!newUnit) return
      if (Array.isArray(newUnit)) {
        state.weightUnit.push(...newUnit)
      } else {
        state.weightUnit.push(newUnit)
      }
    },
    editWeightUnit(state, obj) {
      let oldName = state.weightUnit[obj.index].name
      state.weightUnit[obj.index].name = obj.newName
      state.weightUnit.forEach((item) => {
        if (item.parent_name == oldName) {
          item.parent_name = obj.newName
        }
      })
    },

    delWeightUnit(state, index) {
      const map = {}
      const result = []
      state.weightUnit.forEach((item) => {
        map[item.name] = item
      })
      state.weightUnit.forEach((item) => {
        const parent = map[item.parent_name]
        if (parent) {
          ;(parent.children || (parent.children = [])).push(item)
        } else {
          result.push(item)
        }
      })
      let name = state.weightUnit[index].name
      result.forEach((item, index1) => {
        if (item.name == name) {
          result.splice(index1, 1)
        }
      })
      state.weightUnit = flatten(result)
      state.weightUnit.forEach((item, index2) => {
        if (item.name == name) {
          state.weightUnit.splice(index2, 1)
        }
      })
    },
    setVolumeUnit(state, newUnit) {
      if (!newUnit) return

      if (Array.isArray(newUnit)) {
        state.volumeUnit.push(...newUnit)
      } else {
        state.volumeUnit.push(newUnit)
      }
    },
    editVolumeUnit(state, obj) {
      let oldName = state.volumeUnit[obj.index].name
      state.volumeUnit[obj.index].name = obj.newName
      state.volumeUnit.forEach((item) => {
        if (item.parent_name == oldName) {
          item.parent_name = obj.newName
        }
      })
    },
    delVolumeUnit(state, index) {
      state.volumeUnit.splice(index, 1)
      const map = {}
      const result = []
      state.volumeUnit.forEach((item) => {
        map[item.name] = item
      })
      state.volumeUnit.forEach((item) => {
        const parent = map[item.parent_name]
        if (parent) {
          ;(parent.children || (parent.children = [])).push(item)
        } else {
          result.push(item)
        }
      })
      let name = state.volumeUnit[index].name
      result.forEach((item, index1) => {
        if (item.name == name) {
          result.splice(index1, 1)
        }
      })

      state.volumeUnit = flatten(result)
      state.volumeUnit.forEach((item, index2) => {
        if (item.name == name) {
          state.volumeUnit.splice(index2, 1)
        }
      })
    },
    setOtherOne(state, unit) {
      state.otherOne = unit
    },
    setOtherUnit(state, newUnit) {
      if (!newUnit) return

      if (Array.isArray(newUnit)) {
        state.otherUnit.push(...newUnit)
      } else {
        state.otherUnit.push(newUnit)
      }
    },
    editOtherUnit(state, obj) {
      let oldName = state.otherUnit[obj.index].name
      state.otherUnit[obj.index].name = obj.newName
      state.otherUnit.forEach((item) => {
        if (item.parent_name == oldName) {
          item.parent_name = obj.newName
        }
      })
    },
    delOtherUnit(state, index) {
      state.otherUnit.splice(index, 1)
      const map = {}
      const result = []
      state.otherUnit.forEach((item) => {
        map[item.name] = item
      })
      state.otherUnit.forEach((item) => {
        const parent = map[item.parent_name]
        if (parent) {
          ;(parent.children || (parent.children = [])).push(item)
        } else {
          result.push(item)
        }
      })
      let name = state.otherUnit[index].name
      result.forEach((item, index1) => {
        if (item.name == name) {
          result.splice(index1, 1)
        }
      })

      state.otherUnit = flatten(result)
      state.otherUnit.forEach((item, index2) => {
        if (item.name == name) {
          state.otherUnit.splice(index2, 1)
        }
      })
    },
    changeOtherUnit(state, oldValue) {
      state.otherUnit.forEach((item) => {
        if (item.parent_name == oldValue) {
          item.parent_name = state.otherOne
        }
      })
    },
    clearUnit(state) {
      state.weightUnit = []
      state.volumeUnit = []
      state.otherUnit = []
    },
    clearStore(state) {
      state.store = []
    },
    setStore(state, newStore) {
      state.store.push(newStore)
    },
    setWarehouses(state, newWarehouses) {
      state.warehouses = newWarehouses
    },
  },
  actions: {
    getWarehouses({ commit }) {
      return new Promise((resolve, reject) => {
        get('/entrepot_warehouse/getList')
          .then((res) => {
            if (res.code == 10000) {
              commit('setWarehouses', res.data)
              resolve(res)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
export default goods

import { Toast } from 'vant'
import axios from 'axios'

export default (error) => {
  console.log(error)
  if (/Network Error/.test(error.message)) {
    Toast.fail('网络错误,请重新请求哦')
    // 确保用户可以再次发起请求
    return Promise.resolve({
      data: {
        msg: '网络错误',
      },
    })
  } else if (/^timeout\sof\s.+ms\sexceeded$/.test(error.message)) {
    Toast.fail('请求超时，请重新请求哦')
    // 确保请求超时后用户可以再次发起请求
    return Promise.resolve({
      data: {
        msg: '请求超时',
      },
    })
  } else if (error.message === 'Request failed with status code 500') {
    Toast.fail('服务器异常')
  } else if (axios.isCancel(error)) {
    console.log('repeated request: ' + error.message)
  }
  return Promise.reject(error)
}

import Vue from 'vue'
import Vuex from 'vuex'

import basic from './modules/basic'
import setting from './modules/setting'
import produce from './modules/produce'
import goods from './modules/goods'
import permit from '@/store/modules/permit'
import attendance from '@/store/modules/attendance'
import transport from '@/store/modules/transport'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    basic, // 固定
    setting, // 配置
    produce, // 生产
    goods, // 物品
    permit, // 权限
    attendance, // 生产
    transport, // 运输
  },
})

export default store

export const statBase = (item = {}) => [
  {
    name: 'attendanceStat',
    component: () =>
      import(
        /* webpackChunkName: "attendance" */ '@/views/attendance/stat/index.vue'
      ),
    meta: {
      title: '考勤统计',
      keepAlive: true,
    },
    query: {
      user_key: item['user_key'],
    },
    params: {
      tab: item['tab'],
    },
  },
]

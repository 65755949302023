import Cookies from 'js-cookie'
import store from '@/store'

let flag = window.location.host.split('.')[0]
let tokenKey = `yiyu_mc_${flag}_token`

export function getToken() {
  return Cookies.get(tokenKey)
}

// 权限-登录后要重新加载动态路由
export function setToken(val) {
  store.commit('setIsInit', false)
  return Cookies.set(tokenKey, val)
}

export function removeToken() {
  store.commit('setIsInit', false)
  return Cookies.remove(tokenKey)
}

export const orderBase = (item = {}) => [
  {
    name: 'salesAdd',
    component: () =>
      import(/* webpackChunkName: "sales" */ '@/views/sales/salesEdit'),
    meta: {
      title: '订单', // 添加/修改订单
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      status: item.status,
    },
  },
  {
    name: 'salesDetail',
    component: () =>
      import(/* webpackChunkName: "sales" */ '@/views/sales/salesDetail'),
    meta: {
      title: '订单详情',
    },
    query: {
      orderKey: item['or_id'],
    },
  },
  {
    name: 'feedback',
    component: () =>
      import(/* webpackChunkName: "sales" */ '@/views/sales/feedback'),
    meta: {
      title: '客户反馈',
    },
    query: {
      cf_key: item['cf_key'],
    },
  },
]

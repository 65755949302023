// axios 中间件
// 使用, 在请求前加上 yapi, 然后接上 yapi 上的 Mock 路径.
// 比如, this.$get('yapi/14/attend_apply/getApplyList')
export default (config) => {
  const yapiReg = RegExp(/^yapi/g)
  if (!yapiReg.test(config.url)) return config

  console.log(
    `API %c${config.url}%c 正在使用 yapi Mock 数据...`,
    'color:#fd7739;',
  )
  config.baseURL = 'http://waua.yiyu.plus/mock'
  config.url = config.url.split('yapi')[1]
  return config
}

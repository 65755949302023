// 自定义指令
import store from '@/store'

export function registerDirective(Vue) {
  // 权限
  Vue.directive('permit', {
    inserted(el, binding) {
      if (
        Array.isArray(binding.value) &&
        store.state.basic.roles.every((item) => !binding.value.includes(item))
      )
        el.parentNode && el.parentNode.removeChild(el)
    },
  })

  Vue.directive('page', {
    inserted(el, binding) {
      if (store.state.permit.removeRouters.includes(binding.value))
        el.parentNode && el.parentNode.removeChild(el)
    },
  })

  Vue.directive('handle', {
    inserted(el, binding) {
      if (store.state.permit.removeHandles.includes(binding.value))
        el.parentNode && el.parentNode.removeChild(el)
    },
  })

  // 禁用
  // Vue.directive('disabled', {
  //   bind(el, binding) {
  //     console.log(el, el.getAttribute('disabled'), 'disabled')
  //     // if (!binding.value.includes(store.getters.role_type)) el.setAttribute('disabled', true)
  //   }
  // })

  Vue.directive('myLoad', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted(el, binding) {
      if (binding.value) {
        //始终设置只有一个加载中弹窗
        let myLoadBg = document.getElementById('yiyu-loading-mark')
        if (myLoadBg) {
          myLoadBg.parentNode.removeChild(myLoadBg)
        }
        let div = document.createElement('div')
        div.setAttribute('id', 'yiyu-loading-mark')
        div.innerHTML = `<div class="van-loading van-loading--circular"><span class="van-loading__spinner van-loading__spinner--circular" style="color: rgb(25, 137, 250);"><svg viewBox="25 25 50 50" class="van-loading__circular"><circle cx="50" cy="50" r="20" fill="none"></circle></svg></span></div>` //你也可以去网上找一些酷炫的加载效果放进去，哈哈哈
        // document.body.appendChild(div);
        el.appendChild(div)
      } else {
        let myLoadBg = document.getElementById('yiyu-loading-mark')
        if (myLoadBg) myLoadBg.parentNode.removeChild(myLoadBg)
      }
    },
    // 所在组件VNode更新后调用
    componentUpdated(el, binding) {
      if (binding.value) {
        //始终设置只有一个加载中弹窗
        let myLoadBg = document.getElementById('yiyu-loading-mark')
        if (myLoadBg) {
          myLoadBg.parentNode.removeChild(myLoadBg)
        }
        let div = document.createElement('div')
        div.setAttribute('id', 'yiyu-loading-mark')
        div.innerHTML = `<div class="van-loading van-loading--circular"><span class="van-loading__spinner van-loading__spinner--circular" style="color: rgb(25, 137, 250);"><svg viewBox="25 25 50 50" class="van-loading__circular"><circle cx="50" cy="50" r="20" fill="none"></circle></svg></span></div>`
        el.appendChild(div)
      } else {
        let myLoadBg = document.getElementById('yiyu-loading-mark')
        if (myLoadBg) myLoadBg.parentNode.removeChild(myLoadBg)
      }
    },
  })

  // 动态修改meta.title
  // <div v-title data-title="我是新的标题"></div>
  // <div v-title="我是新的标题"></div>
  Vue.directive('title', {
    inserted: function (el, binding) {
      document.title = binding.value || el.dataset.title
    },
  })
}

import getRouteConf from '@/router/getRouteConf';
import { dectBase } from '@/router/dectRoutes';
import { fairyBase } from '@/router/fairyBase';
import { storeBase } from '@/router/store/storeRoutes';
import { salesBase } from './sales/salesRoutes';
import { produceBase } from './produce/produceRoutes';
import { farmBase } from './farmRoutes';
import { recordBase } from './recordRoutes';
import { techBase } from './techRoutes';
import { noticeBase } from './noticeRoutes';
import { algaBase } from './algaRoutes';
import { settingBase } from './settingRoutes';
import { accept } from './accept.js';
import { memberBase } from './memberRoutes';
import { attendanceBase } from './attendance/attendanceRoutes';
import { transportBase } from './transport/transportRoutes';

let jumpBase = (item) => [
  ...farmBase(item),
  ...dectBase(item),
  ...fairyBase(item),
  ...storeBase(item),
  ...salesBase(item),
  ...produceBase(item),
  ...recordBase(item),
  ...techBase(item),
  ...noticeBase(item),
  ...algaBase(item),
  ...settingBase(item),
  ...memberBase(item),
  ...attendanceBase(item),
  ...transportBase(item),
  ...accept(item),
];

export function jumpMapFn(item) {
  return { ...getRouteConf(jumpBase(item), 'jump') };
}

const asyncRouters = [
  ...getRouteConf(jumpBase()),
  {
    name: 'FF_home',
    component: () => import(/* webpackChunkName: "index" */ '@/views/home'),
    meta: {
      title: '首页',
      keepAlive: true,
    },
  },
];

asyncRouters.forEach((route) => {
  route.path = route.path || '/' + (route.name || '');
});

export default asyncRouters;

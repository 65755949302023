export const farmBase = (item = {}) => [
  {
    name: 'farmMgt',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/farm/index.vue'),
    meta: {
      title: '基地管理',
      keepAlive: true,
    },
    params: {
      key: item['mc_key'],
      // 类型： 1. 育苗 / 2. 种虾（没传的时候，要默认是育苗，兼容以前的跳转）
      tab: item['ws_type'] || 1,
    },
  },
  {
    name: 'farmEdit',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/farm/farmEdit.vue'),
    meta: {
      title: '基地',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      status: item['status'],
      farmKey: item['mc_key'],
      ws_type: item['ws_type'],
    },
  },
  {
    name: 'farmDetail',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/farm/culDetail.vue'),
    meta: {
      title: '育苗基地详情',
      keepAlive: true,
    },
    query: {
      mcKey: item['mc_key'],
      scroll: item['scroll'], // 自动滚动的位置
    },
  },
  {
    name: 'farmPoolDetail',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/farm/culPoolDetail.vue'
      ),
    meta: {
      title: '育苗池详情',
    },
    query: {
      p_key: item['p_key'],
    },
  },
  // 种虾模块-基地部分
  {
    name: 'sBaseDetail',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/farm/seedDetail.vue'),
    meta: {
      title: '种虾基地详情',
      keepAlive: true,
    },
    query: {
      mc_key: item['mc_key'],
      scroll: item['scroll'], // 自动滚动的位置
    },
  },
  {
    name: 'sPoolList',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/farm/seedPoolList.vue'),
    meta: {
      title: '池列表',
      activeKeepAlive: true,
    },
    query: {
      mc_key: item['mc_key'],
      ss_key: item['ss_key'],
    },
  },
  {
    name: 'sPoolDetail',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/farm/seedPoolDetail.vue'
      ),
    meta: {
      title: '基地池详情',
    },
    query: {
      sp_key: item['sp_key'],
      ss_key: item['ss_key'],
      p_key: item['p_key'],
      ap_key: item['ap_key'],
      algae_key: item['algae_key'],
    },
  },
  {
    name: 'poolDetail',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/more/larva/pool.vue'),
    meta: {
      title: '育苗池详情',
    },
    query: {
      lm_id: item['lm_id'],
    },
  },
]

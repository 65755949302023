export const algaBase = (item = {}) => [
  {
    name: 'algaEdit',
    component: () =>
      import(/* webpackChunkName: "alga" */ '@/views/alga/lutaiAlgaEdit.vue'),
    meta: {
      title: '藻类',
      activeKeepAlive: true,
    },
  },
  {
    name: 'containerEdit',
    component: () =>
      import(
        /* webpackChunkName: "alga" */ '@/views/farm/container/containerEdit.vue'
      ),
    meta: {
      title: '容器',
      activeKeepAlive: true,
    },
    query: {
      c_key: item['c_key'],
    },
  },
  {
    name: 'containerList',
    component: () =>
      import(
        /* webpackChunkName: "alga" */ '@/views/farm/container/containerList.vue'
      ),
    meta: {
      title: '容器管理列表',
    },
  },
  {
    name: 'algaAdjust',
    component: () =>
      import(/* webpackChunkName: "alga" */ '@/views/alga/adjust.vue'),
    meta: {
      title: '调整表单',
      activeKeepAlive: true,
    },
    query: {
      algae_key: item['algae_key'],
      ac_key: item['ac_key'],
    },
  },
  {
    name: 'algaCopy',
    component: () =>
      import(/* webpackChunkName: "alga" */ '@/views/alga/algaCopy.vue'),
    meta: {
      title: '复制',
      activeKeepAlive: true,
    },
    query: {
      algae_key: item['algae_key'],
      ac_key: item['ac_key'],
    },
  },
  {
    name: 'algaOut',
    component: () =>
      import(/* webpackChunkName: "alga" */ '@/views/alga/out.vue'),
    meta: {
      title: '淘汰记录',
      activeKeepAlive: true,
    },
    query: {
      algae_key: item['algae_key'],
    },
  },
  {
    name: 'algaListDetail',
    path: '/alga/detail/:key',
    component: () =>
      import(/* webpackChunkName: "alga" */ '@/views/alga/AlgaListDetail.vue'),
    meta: {
      title: '藻类详情',
    },
    params: {
      key: item['algae_key'],
    },
  },
  {
    name: 'algaDistributeRecord',
    component: () =>
      import(
        /* webpackChunkName: "alga" */ '@/views/alga/AlgaDistributeRecord.vue'
      ),
    meta: {
      title: '分配管理',
      activeKeepAlive: true,
    },
  },
  {
    name: 'algaDistributeRecordDetail',
    component: () =>
      import(
        /* webpackChunkName: "alga" */ '@/views/alga/AlgaDistributeRecordDetail.vue'
      ),
    meta: {
      title: '分配详情',
      activeKeepAlive: true,
    },
  },
  {
    name: 'algaeDetail',
    component: () =>
      import(/* webpackChunkName: "alga" */ '@/views/farm/algaeDetail.vue'),
    meta: {
      title: '藻类基地详情',
    },
    query: {
      mc_key: item['mc_key'],
    },
  },
  {
    name: 'aPoolDetail',
    component: () =>
      import(/* webpackChunkName: "alga" */ '@/views/farm/algaePoolDetail.vue'),
    meta: {
      title: '种虾池详情',
    },
    query: {
      ap_key: item['ap_key'],
      algae_key: item['algae_key'],
      p_key: item['p_key'],
    },
  },
  {
    name: 'algaDistribute',
    component: () =>
      import(/* webpackChunkName: "alga" */ '@/views/alga/AlgaDistribute.vue'),
    meta: {
      title: '新增分配',
      activeKeepAlive: true,
    },
  },
  {
    name: 'algaDistributeBatchSelector',
    component: () =>
      import(
        /* webpackChunkName: "alga" */ '@/views/alga/AlgaDistributeBatchSelector.vue'
      ),
    meta: {
      title: '批次选择',
    },
  },
];

export const algaJumpData = {
  algaList: {
    name: 'FF_produce',
    params: {
      tab: 'algae',
    },
  },
};

export const clientBase = (item = {}) => [
  {
    name: 'clientAdd',
    component: () =>
      import(/* webpackChunkName: "sales" */ '@/views/client/add'),
    meta: {
      title: '客户',
      activeKeepAlive: true, // 需要动态缓存的表单
    },
    query: {
      status: item['status'],
    },
  },
  {
    name: 'clientDetail',
    component: () =>
      import(/* webpackChunkName: "sales" */ '@/views/client/detail'),
    meta: {
      title: '客户详情',
    },
    query: {
      clientKey: item['customer_key'],
    },
  },
  {
    name: 'clientMgt',
    component: () =>
      import(/* webpackChunkName: "sales" */ '@/views/more/client/index.vue'),
    meta: {
      title: '客户管理',
      keepAlive: true,
    },
  },
]

import Vue from 'vue'
import Router from 'vue-router'
import asyncRouters from '@/router/index'

Vue.use(Router)

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
//push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    console.log('push捕获', err)
  })
}
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => {
    if (
      err.message.includes('Redirected when going from "/FF_login?redirect')
    ) {
      return
    }
    console.log('replace捕获', err)
  })
}

let noTokenList = [] // 不需要token的页面
let noTokenRouters = [] // 默认加载

noTokenRouters = asyncRouters.filter((item) => item.noToken == true)

// FF_ 表示第一页（app要用到FF）
const staticRouters = [
  {
    path: '/',
    // redirect: { name: 'FF_home' }, // 不是所有人都有首页权限
    redirect: { name: 'FF_more' },
  },
  {
    name: 'FF_login',
    component: () => import(/* webpackChunkName: "index" */ '@/views/login'),
    meta: {
      title: '登录',
      keepAlive: false,
    },
    noToken: true,
  },
  {
    name: 'FF_more',
    component: () => import(/* webpackChunkName: "index" */ '@/views/more'),
    meta: {
      title: '更多',
      keepAlive: true,
    },
  },
  {
    name: 'testView',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/testView.vue'),
    meta: {
      title: '测试界面',
    },
  },
  ...noTokenRouters,
]

// add route path
staticRouters.forEach((route) => {
  route.path = route.path || '/' + (route.name || '')
  if (route.noToken) {
    noTokenList.push(route.name)
  }
})

const router = new Router({
  routes: staticRouters,
  // saveScrollPosition: true, // 使用无效
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export { router, noTokenList, staticRouters }

// {
//   name: "search",
//   component: () => import("@/views/search/index"),
//   meta: {
//     title: "搜索",
//   },
// },
// {
//   name: 'orderSelect',
//   component: () => import(/* webpackChunkName: "index" */'@/views/search/order'),
//   meta: {
//     title: '订单选择',
//   },
// },
// {
//   name: 'batchSelect',
//   component: () => import(/* webpackChunkName: "index" */'@/views/search/batch'),
//   meta: {
//     title: '批次选择',
//   },
// },
// {
//   name: 'search',
//   component: () => import(/* webpackChunkName: "index" */'@/views/search'),
//   meta: {
//     title: '搜索',
//   },
// },
// {
//   name: 'plan',
//   component: () => import(/* webpackChunkName: "index" */'@/views/home/components/plan.vue'),
//   meta: {
//     title: '计划',
//   },
// },

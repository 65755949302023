import { router } from '@/router'
import { Toast } from 'vant'
import { removeToken } from '@/utils/cookie'

// 认证中间件
export default (response) => {
  const res = response?.data || {}
  if (res.code >= 10000 && res.code < 20000) {
    // do nothing
  } else if (res.code === 50009 || res.code === 50002) {
    Toast.fail(res.msg)
    // 未登录
    removeToken()
    // location.reload()
    router.push({
      path: '/FF_login',
      query: {
        redirect: router.currentRoute.fullPath,
      },
    })
  } else {
    Toast.fail(res.msg)
  }

  return response
}

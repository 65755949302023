import { mapGetters } from 'vuex'

const vuexMixin = {
  computed: {
    ...mapGetters([
      'IS_HANDLE',
      'IS_PAGE',
      'FILTER_HANDLE',
      'FILTER_PAGE',
      'IS_BACK',
    ]),
  },
  methods: {
    /**
     * 发布通知
     * @param {String} p 接收人,e.g. '1' 即管理员 'all' 所有人
     * @param {String} title 标题
     * @param {Array} remark 内容 [{label,value,extra}]
     * @param {String} status 状态 add/edit
     * @param {Number} priority 优先级 add/edit
     * @param {Array} re 关联信息 [{type,re_info}] 同接口文档
     */
    // 相关接口：https://www.teambition.com/project/5f62bb6525c7d900446550c9/app/5eba5fba6a92214d420a3219/workspaces/5f62bb65b1a1060016c84d94/docs/5fed85ebeaa1190001ba23d2
    // 相关文档： https://www.teambition.com/project/60ffa2cbbee0dfac6d095c56/app/5eba5fba6a92214d420a3219/workspaces/60ffa2e8ac41f90046f82eed/docs/61288943eaa119000152c2f6
    toNotice({
      p,
      title,
      remark = [],
      re = [],
      status,
      priority = 3,
      user_key = [],
    } = {}) {
      let msg = []
      remark.forEach((item) => {
        item.remark =
          item.label + ':' + (item.value || '暂无') + (item.extra || '')
        msg.push(item.remark)
      })

      if (status == 'add') {
        title = '新增' + title
      } else if (status == 'edit') {
        title = '修改' + title
      }

      this.$replace({
        name: 'publish',
        params: {
          p,
          title,
          remark: msg.join('\n'),
          re,
          priority,
          user_key,
        },
        query: {
          status: 'add',
        },
      })
    },
  },
}

export default vuexMixin

import { get } from '@/utils/http';

const attendance = {
  state: {
    getAttendInfoLoading: false,
    hasGetInfo: false, // 判断是否有获取考勤信息
    groupType: {
      1: '固定时间上下班',
      2: '自由上下班',
    },
    clockType: {
      1: '上班',
      2: '下班',
    },
    attendType: {
      1: '内勤',
      2: '外勤',
    },
    applyType: {
      1: '请假',
      2: '出差',
      3: '补卡',
    },
    leaveType: {
      1: '事假',
      2: '病假',
      3: '调休',
      4: '年假',
    },
    attendStatus: {
      1: '正常',
      2: '迟到',
      3: '早退',
      4: '缺卡',
    },
    checkStatus: {
      1: '待审批',
      2: '审核中',
      3: '已通过',
      4: '已拒绝',
      5: '取消',
    },
    isInCharge: false, // 是否加入考勤组
    attendInfo: {
      type: undefined, // '考勤类型 1 固定时间， 2 自由打卡',
      charge: undefined, // '负责人key',
      attend_range: {}, // {// 上班时间end: '结束时间',start: '开始时间',},
      rest_range: {}, // {// 休息时间end: '结束时间',start: '开始时间',},
      compensation: undefined, // '最多补卡次数',
      field: undefined, // '是否可外勤 1 可以外勤， 2 不可外勤',
      position: [], //  [// 打卡地点{addr: '地点名称',latitude: '纬度',longitude: '经度',},],
      distance: undefined, // '打卡范围',
      mc_list: [], // 负责基地
    },
    AMap: undefined,
    status: {
      1: { label: '待审批', type: 'primary' },
      2: { label: '审核中', type: 'primary' },
      3: { label: '已通过', type: 'success' },
      4: { label: '已拒绝', type: 'danger' },
      5: { label: '取消', type: undefined },
    },
  },
  mutations: {
    setAttendInfoLoading: (state, bool) => {
      state.getAttendInfoLoading = bool;
    },
    setAttendInfo: (state, attendInfo) => {
      state.attendInfo = attendInfo;
      state.isInCharge = Boolean(attendInfo.charge);
      state.hasGetInfo = true;
    },
    setAMap: (state, AMap) => {
      state.AMap = AMap;
    },
  },
  actions: {
    // 测试
    getAttendInfo({ state, commit }) {
      if (state.getAttendInfoLoading) {
        // 避免连续请求
        return Promise.resolve(); // 放弃请求直接通过
      }
      return new Promise((resolve, reject) => {
        commit('setAttendInfoLoading', true);
        get('/attend_user/getInfo', {})
          .then((response) => {
            let group = response.data?.group || {};
            group.mc_list = response.data?.mc_list;
            commit('setAttendInfo', group || {});

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            commit('setAttendInfoLoading', false);
          });
      });
    },
    loadAMap({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.AMap) {
          resolve(state.AMap);
        } else {
          import(/* webpackChunkName: "attendance" */ '@amap/amap-jsapi-loader')
            .then((AMapLoader) => {
              AMapLoader.load({
                key: '40849f0f973078ea5be74985436ba250', // 申请好的Web端开发者Key，首次调用 load 时必填
                version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                plugins: [
                  'AMap.Geolocation', // 定位，提供了获取用户当前准确位置、所在城市的方法
                ],
                AMapUI: {
                  version: '1.1', // AMapUI 缺省 1.1
                  plugins: [
                    'misc/PositionPicker', // 拖拽选址
                    'misc/PoiPicker', // POI选点
                    'misc/MarkerList',
                  ], // 需要加载的 AMapUI ui插件
                },
                Loca: {
                  // 是否加载 Loca， 缺省不加载
                  version: '1.3.2', // Loca 版本，缺省 1.3.2
                },
              })
                .then((AMap) => {
                  commit('setAMap', AMap);
                  resolve(AMap);
                })
                .catch((e) => {
                  console.error('地图加载错误', e);
                  reject(e);
                });
            })
            .catch((e) => {
              console.error('地图导入失败', e);
              reject(e);
            });
        }
      });
    },
  },
};

export default attendance;

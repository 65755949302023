export const clockBase = (item = {}) => [
  {
    name: 'attendanceClock',
    component: () =>
      import(
        /* webpackChunkName: "attendance" */ '@/views/attendance/clock/index.vue'
      ),
    meta: {
      title: '打卡',
      // keepAlive: true,
    },
    params: {
      tab: item['tab'],
      token: item['token'],
    },
    noToken: true,
  },
  {
    name: 'clockDetail',
    component: () =>
      import(
        /* webpackChunkName: "attendance" */ '@/views/attendance/clock/clockDetail.vue'
      ),
    meta: {
      title: '打卡详情',
    },
    query: {
      user_key: item['user_key'],
    },
    params: {
      date: item['date'],
    },
  },
];

export const applyBase = (item = {}) => [
  {
    name: 'applyAdd',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/store/application/apply/applyAdd.vue'
      ),
    meta: {
      title: '新增申请',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
      apply_key: item['apply_key'],
      moreApply: item['moreApply'],
      goodskeyList: item['goodskeyList'],
    },
  },
  {
    name: 'apply',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/store/application/apply/apply.vue'
      ),
    meta: {
      title: '申请管理',
    },
    query: {
      type: item['type'],
      filters: item['filters'],
    },
  },
  {
    name: 'applyDetail',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/store/application/apply/applyDetail.vue'
      ),
    meta: {
      title: '申请详情',
    },
    query: {
      key: item['key'],
    },
  },

  {
    name: 'examine',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/store/application/apply/examine.vue'
      ),
    meta: {
      title: '审核界面',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
      key: item['key'],
      edit_flag: item['edit_flag'],
      type: item['type'],
    },
  },
  {
    name: 'takeOver',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/store/application/apply/takeOver.vue'
      ),
    meta: {
      title: '确认收货',
      activeKeepAlive: true,
    },
    query: {
      goodsList: item['goodsList'],
      key: item['key'],
    },
  },
  {
    name: 'applyGoodsDetail',
    component: () =>
      import(
        /* webpackChunkName: "produce" */ '@/views/store/application/apply/applyGoodsDetail.vue'
      ),
    meta: {
      title: '物品详情',
    },
    query: {
      apply_key: item['apply_key'],
      goods_key: item['goods_key'],
    },
  },
];

import { larvaBase } from './larvaRoutes'
import { seedBase } from './seedRoutes'

export const produceBase = (item = {}) => [
  {
    name: 'FF_produce',
    component: () =>
      import(/* webpackChunkName: "produce" */ '@/views/produce'),
    meta: {
      title: '生产',
      keepAlive: true,
    },
    query: {
      type: item['larva_type'], // 育苗类别-arr
      supplier: item['s_key'], // 批次来源-arr
      h_date: item['h_date'], // 出苗日期-arr
      mc_key: item['mc_key'], // 基地-arr
    },
    params: {
      tab: item['tab'],
    },
  },
  ...larvaBase(item),
  ...seedBase(item),
]
